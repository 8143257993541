import { Echarts } from 'components/shared/echarts/echarts'
import { RetoCardAsk } from 'components/shared/reto-card-asks/reto-card-ask'
import dayjs from 'dayjs'
import useRetoQuery from 'hooks/query/use-reto.query'
import { Loader2Icon } from 'lucide-react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'
import { getPieChartAskOptions } from 'utils/get-pie-chart-ask-options'

type Props = {}

const TabResults = (props: Props) => {
  const { id } = useParams()
  const { user } = useStore()

  const retoQuery = useRetoQuery({
    enabled: !!id,
    retoId: Number(id),
    variables: { populate: 'asks' }
  })

  const reto = retoQuery.data?.data.data
  const myResults = reto?.attributes.resultados.find((r) => r.id === user?.id)
  const asks = myResults?.asksAndResponses.reduce(
    (acc, { correct, marked }) => {
      if (marked === null) return acc
      if (marked === correct) return { ...acc, correct: acc.correct + 1 }

      return { ...acc, fail: acc.fail + 1 }
    },
    { fail: 0, blank: 0, correct: 0 }
  ) ?? { fail: 0, blank: 0, correct: 0 }

  const chartOptions = useMemo(
    () =>
      getPieChartAskOptions({
        blank:
          (myResults?.asksAndResponses?.length ?? 0) -
          asks?.correct -
          asks?.fail,
        correct: asks?.correct,
        fail: asks?.fail
      }),
    [myResults?.asksAndResponses.length, asks?.correct, asks?.fail]
  )

  return (
    <>
      {retoQuery.isLoading && (
        <div className="w-full h- grid place-items-center pt-20">
          <Loader2Icon className="animate-spin" />
        </div>
      )}

      {!retoQuery.isLoading && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6">
            <Echarts className="w-full aspect-1" option={chartOptions} />

            <div className="flex justify-around items-center text-center mb-6">
              <div>
                <p>Nota final</p>
                <p className="font-bold">
                  {' '}
                  {myResults?.average.toFixed(1)} / 10{' '}
                </p>
              </div>

              <div>
                <p>Tiempo total</p>
                <p className="font-bold">
                  {dayjs(myResults?.time, 'HH:mm:ss.SSS').format('HH:mm:ss')}
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-xl md:text-3xl font-semibold mb-3">Preguntas</h2>

          {myResults?.asksAndResponses?.map(
            ({ askId, marked, correct }, idx) => (
              <RetoCardAsk
                key={askId}
                askId={askId}
                idx={idx + 1}
                marked={marked}
                correct={correct}
                showExplanation
              />
            )
          )}
        </>
      )}
    </>
  )
}

export default TabResults
