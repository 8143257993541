import {
  Tabs,
  TabsList,
  TabsContent,
  TabsTrigger
} from 'components/shared/tabs/tabs'

import TabStatistics from './tab-statistics'
import TabOposicionHistory from './tab-oposicion-history'

type Props = {}

const Actividad = (props: Props) => {
  return (
    <>
      <div className="w-[90vw] max-w-5xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl md:text-3xl font-semibold">Actividad</h2>
        </div>

        <Tabs defaultValue="stats">
          <TabsList className="w-full">
            <TabsTrigger value="stats" className="w-full">
              Estadísticas
            </TabsTrigger>
            <TabsTrigger value="history" className="w-full">
              Historial
            </TabsTrigger>
          </TabsList>
          <TabsContent value="stats">
            <TabStatistics />
          </TabsContent>
          <TabsContent value="history">
            <TabOposicionHistory />
          </TabsContent>
        </Tabs>
      </div>
    </>
  )
}

export default Actividad
