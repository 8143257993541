import { useExamStore } from 'store/exam.store'
import { Card } from 'components/shared/card/card'
import { OficialTestQueryResponse } from 'utils/axios-sdk'
import { AwardIcon } from 'lucide-react'

type Props = {}

export type OficialExamCardProps = OficialTestQueryResponse & {
  onClick?: () => void
}

const OficialExamCard = ({ onClick, ...oficialExam }: OficialExamCardProps) => {
  return (
    <Card className="p-3 md:p-5 mb-3" onClick={onClick}>
      <div className="flex items-center gap-3 mb-2">
        <AwardIcon />
        <h3 className="text-xl font-semibold">
          {oficialExam?.attributes?.nombre}
        </h3>
      </div>

      <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
        Haz un simulacro de examen oficial
      </p>
    </Card>
  )
}

const SelectOficialExam = (props: Props) => {
  const { set, oficialExams } = useExamStore((state) => ({
    set: state.set,
    oficialExams: state.oficialExams
  }))

  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">
          Selecciona el test oficial
        </h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, fuga?
          Itaque autem pariatur deleniti dolorem necessitatibus dignissimos
          quam.
        </h3>
      </div>

      {oficialExams.length === 0 && (
        <div className="w-full h-full flex-1 grid place-items-center">
          <div className="text-center space-y-3">
            <p>No hay test oficiales</p>
            <button onClick={() => set({ type: null })} className="underline">
              Volver
            </button>
          </div>
        </div>
      )}

      {oficialExams.map((exam) => (
        <OficialExamCard
          key={exam.id}
          onClick={() => {
            const temas = exam?.attributes?.asks?.data
              .map((ask) => ask.attributes.temas?.data)
              .flat()

            set({
              view: 'list-asks',
              selectedTopics: temas?.map((tema) => tema?.id!),
              numberOfAsks: exam?.attributes?.asks?.data.length,
              sliceAsks: exam?.attributes?.asks?.data.map((ask) => ask.id)
            })
          }}
          {...exam}
        />
      ))}
    </>
  )
}

export default SelectOficialExam
