import { useMutation } from '@tanstack/react-query'

import { axiosClient } from 'utils/axios-client'
import { BlankQuestionMutationResponse } from 'utils/axios-sdk'

type BlankAskMutationVariables = {
  asks: string[]
  users_permissions_user: string
}

export const blankQuestionsMutation = (props: BlankAskMutationVariables) => {
  return axiosClient.post<{ data: BlankQuestionMutationResponse }>(
    '/blank-questions',
    {
      data: props
    }
  )
}

const useBlankQuestionsMutation = () => {
  return useMutation({ mutationFn: blankQuestionsMutation })
}

export default useBlankQuestionsMutation
