import clsx from 'clsx'
import { CheckIcon } from 'lucide-react'
import React, { ComponentPropsWithRef } from 'react'
import { cn } from 'utils/cn'

type CardProps = ComponentPropsWithRef<'div'>

const Card = (props: CardProps) => {
  return (
    <div
      {...props}
      className={cn(
        'group relative bg-white dark:bg-neutral-900 border border-slate-200 dark:border-slate-700 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform cursor-pointer',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

type CardHeaderProps = ComponentPropsWithRef<'div'>

const CardHeader = (props: CardHeaderProps) => {
  return (
    <div className={clsx('p-3 sm:p-5 space-y-2', props.className)}>
      {props.children}
    </div>
  )
}

type CardBodyProps = ComponentPropsWithRef<'div'>

const CardBody = (props: CardBodyProps) => {
  return (
    <div className={clsx('flex-grow flex gap-6', props.className)}>
      {props.children}
    </div>
  )
}

type CardFooterProps = ComponentPropsWithRef<'div'>

const CardFooter = (props: CardFooterProps) => {
  return (
    <div className={clsx('flex items-center space-x-3', props.className)}>
      {props.children}
    </div>
  )
}

type CardCheckboxProps = ComponentPropsWithRef<'div'> & {
  checked: boolean
  onChange?: (checked: boolean) => void
}

const CardCheckbox = (props: CardCheckboxProps) => (
  <button
    className={clsx(
      'w-9 h-9 rounded-full border dark:border-white grid place-items-center',
      'transition-colors duration-300 ease-in-out',
      { 'bg-black dark:bg-white': props.checked }
    )}
  >
    <CheckIcon
      className={clsx({
        'stroke-white dark:stroke-black': props.checked,
        'stroke-transparent': !props.checked
      })}
    />
  </button>
)

export { Card, CardHeader, CardBody, CardFooter, CardCheckbox }
