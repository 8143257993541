import { Loader2 } from 'lucide-react'
import useTemariosQuery from 'hooks/query/use-temarios.query'
import { useNavigate } from 'react-router-dom'
import usePagination from 'hooks/use-pagination'
import CustomPagination from 'components/shared/custom-pagination'

import { Card } from 'components/shared/card/card'
import { useExamStore } from 'store/exam.store'
import { useStore } from 'store'

type Props = {}

const TemariosPage = (props: Props) => {
  const navigate = useNavigate()
  const pagination = usePagination()
  const user = useStore((state) => state.user)
  const reset = useExamStore((state) => state.reset)

  const { data, error, isLoading } = useTemariosQuery({
    onSuccess: (data) => {
      pagination.setPageCount(data.meta.pagination.pageCount ?? 0)
    },
    variables: {
      populate: 'temas',
      pagination: {
        page: pagination.state.page,
        pageSize: pagination.state.pageSize
      }
    }
  })

  if (isLoading) {
    return (
      <div className="w-full grid place-items-center h-[100dvh]">
        <Loader2 className="animate-spin" size={64} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="w-full grid place-items-center h-[100dvh]">
        <p>Ha ocurrido un error</p>
      </div>
    )
  }

  if (!data) {
    return (
      <div className="w-full grid place-items-center h-[100dvh]">
        <p>No se han encontrado temarios</p>
      </div>
    )
  }

  return (
    <div className="w-[90vw] max-w-5xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">Temarios</h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, fuga?
          Itaque autem pariatur deleniti dolorem necessitatibus dignissimos
          quam.
        </h3>
      </div>

      <div className="flex-1">
        {data?.data?.data?.length > 0 && (
          <>
            {data?.data?.data?.map((temario) => (
              <Card
                key={temario.id}
                className="p-3 md:p-5 mb-3"
                onClick={() => {
                  if (!user) {
                    navigate('/login?redirect=/temarios/' + temario.id)
                    return
                  }

                  reset()
                  navigate(`/temarios/${temario.id}`)
                }}
              >
                <h3 className="text-xl font-semibold mb-1">
                  {temario.attributes.title}
                </h3>
              </Card>
            ))}
          </>
        )}
      </div>

      <CustomPagination
        className="ml-auto my-6"
        page={pagination.state.page}
        pageCount={pagination.state.pageCount}
        onPageChange={pagination.onPageChange}
      />
    </div>
  )
}

export default TemariosPage
