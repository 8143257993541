import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { TemarioQueryResponse } from 'utils/axios-sdk'

type Props = {
  temarioId?: number
  enabled?: boolean
  variables?: object
}

export const temarioQuery = (temarioId?: number, params?: object) => {
  return axiosClient.get<{ data: TemarioQueryResponse }>(
    '/temarios/' + temarioId,
    { params }
  )
}

const useTemarioQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['temario', props.temarioId, props.variables],
    queryFn: async ({ queryKey }) => {
      return temarioQuery(queryKey[1] as number, queryKey[2] as object)
    }
  })
}

export default useTemarioQuery
