import { cn } from 'utils/cn'
import { useMemo } from 'react'
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '../accordion/accordion'
import { Trash2Icon } from 'lucide-react'
import { BASE_URL } from 'utils/axios-client'
import { Ask, WithAtributtes } from 'utils/axios-sdk'

export const SimpleCardAsk = ({
  idx,
  ask: _ask,
  onDelete
}: {
  idx: number
  ask: WithAtributtes<Ask>
  onDelete?: (askId: number) => void
}) => {
  const askId = _ask.id
  const ask = _ask.attributes
  const selectedAnswer = null

  const asks = useMemo(() => {
    const payload = [
      {
        id: 'A',
        children: ask?.responseA,
        hasChildren: !!ask?.responseA
      },
      {
        id: 'B',
        children: ask?.responseB,
        hasChildren: !!ask?.responseB
      },
      {
        id: 'C',
        children: ask?.responseC,
        hasChildren: !!ask?.responseC
      },
      {
        id: 'D',
        children: ask?.responseD,
        hasChildren: !!ask?.responseD
      }
    ] as const

    return payload.filter((item) => item.hasChildren)
  }, [ask?.responseA, ask?.responseB, ask?.responseC, ask?.responseD])

  return (
    <AccordionItem value={String(askId)}>
      <AccordionTrigger
        showIndicator={false}
        headerClassName="items-center"
        rightElement={
          <button
            onClick={() => onDelete?.(askId)}
            className="p-3 h-max rounded bg-red-500 dark:bg-red-900"
          >
            <Trash2Icon className="w-4 h-4 text-white" />
          </button>
        }
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex gap-3 font-bold">
            <span>{idx > 10 ? idx : `0${idx}`}.</span>
            <span>{ask?.description}</span>
          </div>
        </div>
      </AccordionTrigger>

      <AccordionContent>
        <div>
          {BASE_URL && ask?.img?.data?.attributes?.url && (
            <img
              alt={ask?.description}
              className="w-full aspect-[16_/_9] rounded-md"
              src={(BASE_URL ?? '') + ask?.img?.data?.attributes?.url}
            />
          )}

          <ul className="flex flex-col">
            {asks.map(({ id, children }, index) => (
              <li
                key={index}
                className={cn(
                  'w-full border border-transparent',
                  'border-b-slate-300 dark:border-b-slate-700 last:border-b-0'
                )}
              >
                <button
                  disabled={selectedAnswer !== null}
                  className="flex w-full gap-3 items-center py-3 pl-2 text-left"
                >
                  <span className="w-8 h-8 grid place-items-center border dark:border-slate-700 rounded-full">
                    {String.fromCharCode(65 + index)}
                  </span>
                  <span className="flex-1">{children}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}
