import { motion } from 'framer-motion'
import { Dialog } from '@headlessui/react'

import { Slider } from '../slider/slider'
import Button from 'shared/Button/Button'
import { useExamStore } from 'store/exam.store'
import { useMemo, useState } from 'react'

type Props = {
  isOpen: boolean
  close: () => void
}

const SelectNumberAsks = (props: Props) => {
  const [numAsks, setNumAsks] = useState<number | null>(null)
  const { set, topics, selectedTopics, sliceAsks, numberOfAsks } = useExamStore(
    (state) => ({
      set: state.set,
      topics: state.topics,
      sliceAsks: state.sliceAsks,
      numberOfAsks: state.numberOfAsks,
      selectedTopics: state.selectedTopics
    })
  )

  const max = useMemo(() => {
    const asks: number[] = []

    for (const topicId of selectedTopics) {
      const topicAsks = topics[topicId] ?? []
      asks.push(...topicAsks)
    }

    return new Set(asks).size
  }, [topics, selectedTopics])

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.close()
        setNumAsks(null)
      }}
      className="fixed inset-0 z-50 flex items-center justify-center "
    >
      <Dialog.Overlay
        as={motion.div}
        key="backdrop"
        className="fixed inset-0 z-30 bg-black bg-opacity-50"
        initial={{ opacity: 0 }}
        animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
      />

      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={
          props.isOpen ? { scale: 1, opacity: 1 } : { scale: 0.5, opacity: 0 }
        }
        className="p-3 md:p-5 bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 rounded relative z-50 flex flex-col aspect-[3/2] w-full max-w-sm"
      >
        <h3 className="text-lg md:text-xl font-semibold mb-1">
          Cantidad de preguntas
        </h3>
        <p className="text-neutral-500 dark:text-neutral-400 text-sm">
          Selecciona cuantas preguntas quieres responder.
        </p>

        <div className="my-6 w-full flex flex-col space-y-3 px-3">
          <span className="text-3xl mx-auto font-bold">{numAsks ?? max}</span>
          <Slider
            min={1}
            step={1}
            max={max}
            defaultValue={[max]}
            onValueChange={(e) => {
              setNumAsks(e?.[0] ?? max)
            }}
          />
        </div>

        <Button
          className="mt-auto"
          onClick={() => {
            if (numberOfAsks === null && sliceAsks.length === 0) {
              const asks: number[] = []

              for (const topicId of selectedTopics) {
                const topicAsks = topics[topicId] ?? []
                for (const ask of topicAsks) {
                  if (!asks.includes(ask)) asks.push(ask)
                }
              }

              set({
                numberOfAsks: numAsks ?? max,
                sliceAsks: asks.slice(0, numAsks ?? max)
              })
            }

            setNumAsks(null)
            set({ view: 'correction' })
            props.close()
          }}
        >
          Guardar
        </Button>
      </motion.div>
    </Dialog>
  )
}

export default SelectNumberAsks
