import axios from 'axios'
import { useStore } from 'store'

axios.interceptors.request.use(
  function (config) {
    const token = useStore.getState()?.jwt
    if (!token) return config

    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export * from './oposicion'
export * from './convocatoria'

export const BASE_URL = 'https://bomberos-app.cloud.desarrollosmoyan.com'
export const BASE_API_URL =
  'https://bomberos-app.cloud.desarrollosmoyan.com/api'
