import { useQuery } from '@tanstack/react-query'
import { temarioQuery } from 'hooks/query/use-temario.query'
import { Loader2 } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'
import { ExamType, useExamStore } from 'store/exam.store'

import SelectTopics from './select-topics'
import SelectExamType from './select-exam-type'
import SelectChallengeType from './select-challenge-type'
import CorrectionType from './correction-type'
import ListAsks from './list-asks'
import Resume from './resume'
import { failedQuestionsQuery } from 'hooks/query/use-failed-questions.query'
import { blankQuestionsQuery } from 'hooks/query/use-blank-questions.query'
import GenerateChallenge from './generate-challenge'
import JoinChallenge from './join-challenge'
import ShareChallenge from './share-challenge-type'

const Temario = () => {
  const { id } = useParams()
  const { type, view } = useExamStore((state) => ({
    type: state.type,
    view: state.view
  }))

  const { isLoading } = useQuery({
    enabled: id !== undefined && type !== null,
    queryKey: ['exam-data', Number(id), type],
    queryFn: async ({ queryKey }) => {
      const { set } = useExamStore.getState()
      const type = queryKey[2] as ExamType | null

      set({ topics: {}, asks: {} })

      const res = await temarioQuery(queryKey[1] as number, {
        populate: 'temas,temas.TemaRelation,temas.TemaRelation.temas'
      })

      if (!res?.data?.data?.id) {
        throw new Error('Error fetching oposicion')
      }

      const user = useStore.getState()?.user
      const askIds: Record<number, number[]> = {}
      const topicIds: Record<number, number[]> = {}
      const allTopics = res.data?.data?.attributes?.temas?.data ?? []

      for (const topic of allTopics) {
        if (typeof topic.id === 'number') {
          const asks = topic?.attributes?.TemaRelation?.data ?? []
          topicIds[topic.id] = asks.map((ask) => ask.id)

          for (const ask of asks) {
            const topics = ask?.attributes?.temas?.data ?? []
            askIds[ask?.id] = topics.map((tema) => tema.id)
          }
        }
      }

      if (user?.id && type === 'failed') {
        const failed = await failedQuestionsQuery({
          populate: 'asks',
          filters: { users_permissions_user: { id: { $eq: user.id } } }
        })

        const failedAsks =
          failed?.data?.data?.[0]?.attributes?.asks?.data?.map(
            (ask) => ask.id
          ) ?? []

        for (const askId of Object.keys(askIds)) {
          if (!failedAsks.includes(Number(askId))) {
            delete askIds[Number(askId)]
          }
        }

        for (const topicId of Object.keys(topicIds)) {
          const asks = topicIds[Number(topicId)]
          const hasFilterAsk = asks.some((ask) => failedAsks.includes(ask))
          if (!hasFilterAsk) {
            delete topicIds[Number(topicId)]
          } else {
            topicIds[Number(topicId)] = asks.filter((ask) =>
              failedAsks.includes(ask)
            )
          }
        }
      }

      if (user?.id && type === 'blank') {
        const blank = await blankQuestionsQuery({
          populate: 'asks',
          filters: { users_permissions_user: { id: { $eq: user.id } } }
        })

        const blankAsks =
          blank?.data?.data?.[0]?.attributes?.asks?.data?.map(
            (ask) => ask.id
          ) ?? []

        for (const askId of Object.keys(askIds)) {
          if (!blankAsks.includes(Number(askId))) {
            delete askIds[Number(askId)]
          }
        }

        for (const topicId of Object.keys(topicIds)) {
          const asks = topicIds[Number(topicId)]
          const hasFilterAsk = asks.some((ask) => blankAsks.includes(ask))
          if (!hasFilterAsk) {
            delete topicIds[Number(topicId)]
          } else {
            topicIds[Number(topicId)] = asks.filter((ask) =>
              blankAsks.includes(ask)
            )
          }
        }
      }

      set({
        asks: askIds,
        topics: topicIds,
        recordAlias: res?.data?.data?.attributes?.recordAlias ?? {}
      })
      return res.data.data
    }
  })

  return (
    <div className="w-[90vw] max-w-5xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      {isLoading && (
        <div className="w-full h-full grid place-items-center flex-1">
          <Loader2 className="animate-spin" size={64} />
        </div>
      )}

      {!isLoading && (
        <>
          {type === null && view === null && <SelectExamType />}
          {type !== null && view === null && <SelectTopics />}

          {type !== null && view !== null && (
            <>
              {view === 'challenge-type' && <SelectChallengeType />}
              {view === 'generate-challenge' && <GenerateChallenge />}
              {view === 'join-challenge' && <JoinChallenge />}
              {view === 'share-challenge' && <ShareChallenge />}
              {view === 'correction' && <CorrectionType />}
              {view === 'list-asks' && <ListAsks />}
              {view === 'resume' && <Resume />}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Temario
