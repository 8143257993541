import { useMutation } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import { BlankQuestionMutationResponse } from 'utils/axios-sdk'

type UpdateBlankQuestionProps = {
  blankQuestionId: string
  asks?: string[]
  users_permissions_user?: string
}

export const updateBlankQuestion = ({
  blankQuestionId,
  ...rest
}: UpdateBlankQuestionProps) => {
  return axiosClient.put<{ data: BlankQuestionMutationResponse }>(
    '/blank-questions/' + blankQuestionId,
    { data: rest }
  )
}

const useUpdateBlankQuestionMutation = () => {
  return useMutation({
    mutationFn: updateBlankQuestion
  })
}

export default useUpdateBlankQuestionMutation
