import { Echarts } from 'components/shared/echarts/echarts'
import { RetoCardAsk } from 'components/shared/reto-card-asks/reto-card-ask'
import dayjs from 'dayjs'
import useOposicionesResponsedQuery from 'hooks/query/use-oposiciones-responsed.query'
import { Loader2Icon } from 'lucide-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'
import { getPieChartAskOptions } from 'utils/get-pie-chart-ask-options'

type Props = {}

const DetalleActividad = (props: Props) => {
  const { id } = useParams()
  const { user } = useStore()

  const oposicionQuery = useOposicionesResponsedQuery({
    enabled: !!id,
    variables: {
      populate: 'asks,oposicion,temario',
      filters: {
        id: { $eq: id }
      }
    }
  })

  const op = oposicionQuery.data?.data.data?.[0]
  const isOposicion = !!op?.attributes?.oposicion?.data?.id

  const asks = op?.attributes?.asksAndResponses.reduce(
    (acc, { correct, marked }) => {
      if (marked === null) return { ...acc, blank: acc.blank + 1 }
      if (marked === correct) return { ...acc, correct: acc.correct + 1 }

      return { ...acc, fail: acc.fail + 1 }
    },
    { fail: 0, blank: 0, correct: 0 }
  ) ?? { fail: 0, blank: 0, correct: 0 }

  const chartOptions = useMemo(
    () =>
      getPieChartAskOptions({
        blank:
          (op?.attributes?.asksAndResponses?.length ?? 0) -
          asks?.correct -
          asks?.fail,
        correct: asks?.correct,
        fail: asks?.fail
      }),
    [op?.attributes?.asksAndResponses.length, asks?.correct, asks?.fail]
  )

  return (
    <div className="w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      {oposicionQuery.isLoading && (
        <div className="w-full h- grid place-items-center pt-20">
          <Loader2Icon className="animate-spin" />
        </div>
      )}

      {!oposicionQuery.isLoading && (
        <>
          <h2 className="text-xl md:text-3xl font-semibold mb-6">
            {isOposicion
              ? op?.attributes.oposicion?.data.attributes.nombre
              : op?.attributes?.temario?.data?.attributes?.title}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6">
            <Echarts className="w-full aspect-1" option={chartOptions} />

            <div className="flex justify-around items-center text-center mb-6">
              <div>
                <p>Nota final</p>
                <p className="font-bold">
                  {' '}
                  {op?.attributes?.average.toFixed(1)} / 10{' '}
                </p>
              </div>

              <div>
                <p>Tiempo total</p>
                <p className="font-bold">
                  {dayjs(op?.attributes?.time, 'HH:mm:ss.SSS').format(
                    'HH:mm:ss'
                  )}
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-xl md:text-3xl font-semibold mb-3">Preguntas</h2>

          {op?.attributes?.asksAndResponses?.map(
            ({ askId, marked, correct }, idx) => (
              <RetoCardAsk
                key={askId}
                askId={askId}
                idx={idx + 1}
                marked={marked}
                correct={correct}
                showExplanation
              />
            )
          )}
        </>
      )}
    </div>
  )
}

export default DetalleActividad
