import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { RetoQueryResponse } from 'utils/axios-sdk'

type Props = {
  enabled?: boolean
  variables?: object
}

export const retosQuery = (params?: object) => {
  return axiosClient.get<{ data: RetoQueryResponse[] }>('/retos', {
    params
  })
}

const useRetosQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['retos', props.variables],
    queryFn: async ({ queryKey }) => {
      return retosQuery(queryKey[1] as object)
    }
  })
}

export default useRetosQuery
