import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { OficialTestQueryResponse } from 'utils/axios-sdk'

type Props = {
  enabled?: boolean
  variables?: object
}

export const oficialTestQuery = (params?: object) => {
  return axiosClient.get<{ data: OficialTestQueryResponse[] }>(
    '/oficial-tests',
    { params }
  )
}

const useOficialTestQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['oficial-tests', props.variables],
    queryFn: async ({ queryKey }) => {
      return oficialTestQuery(queryKey[1] as object)
    }
  })
}

export default useOficialTestQuery
