import {
  Book,
  HeartIcon,
  LogInIcon,
  LogOutIcon,
  SwordsIcon,
  UserIcon
} from 'lucide-react'
import React, { ComponentPropsWithoutRef } from 'react'
import { cn } from 'utils/cn'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../dropdown-menu/dropdown-menu'
import { useStore } from 'store'
import { Link, useNavigate } from 'react-router-dom'

type Props = ComponentPropsWithoutRef<'button'>

const UserMenu = (props: Props) => {
  const { user, logout } = useStore()
  const navigate = useNavigate()

  if (!user) {
    return (
      <button
        {...props}
        onClick={() => navigate('/login')}
        className={cn(
          'text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center',
          props.className
        )}
      >
        <LogInIcon size={24} />
      </button>
    )
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            {...props}
            className={cn(
              'text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center',
              props.className
            )}
          >
            <UserIcon size={24} />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>
            <p className="text-center mb-1">{user?.username}</p>
            <p className="text-sm font-light text-center">{user?.email}</p>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link to="/retos" className="py-3">
              <SwordsIcon size={20} className="mr-2" />
              Retos
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/actividad" className="py-3">
              <HeartIcon size={20} className="mr-2" />
              Actividad
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/mis-test" className="py-3">
              <Book size={20} className="mr-2" />
              Mis test
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={logout} className="py-3">
            <LogOutIcon size={20} className="mr-2" />
            Cerrar sesión
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export { UserMenu }
