import { MIS_TEST } from 'data/mis-test'
import Button from 'shared/Button/Button'
import { ArrowRight, PlusIcon } from 'lucide-react'
import { Card, CardBody } from 'components/shared/card/card'
import { Link } from 'react-router-dom'

type Props = {}

const MisTemas = (props: Props) => {
  return (
    <div className="w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl md:text-3xl font-semibold">Mis test</h2>

        <Button href="/mis-test/nuevo">
          <PlusIcon className="w-4 h-4 mr-2" />
          Agregar test
        </Button>
      </div>

      <div>
        {MIS_TEST.map((test) => (
          <Link key={test.id} to={`/mis-test/${test.id}`}>
            <Card>
              <CardBody className="p-3">
                <div className="flex items-center justify-between w-full">
                  <h3 className="text-lg font-semibold">{test.title}</h3>
                  <ArrowRight className="w-4 h-4" />
                </div>
              </CardBody>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default MisTemas
