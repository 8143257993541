import { useEffect, useId, useState } from 'react'
import { VectorMap } from '@south-paw/react-vector-maps'

import {
  BASE_URL,
  getConvocatorias,
  TConvocatoriasResponse,
  type TOposicionesConvocatoriaResponse
} from 'api'

import spain from '../../../data/jsons/spain-provinces.json'
import classes from './style.module.css'

type SpainMapSvgProps = {
  onSelectProvince: (convocatoriaId: number | null) => void
}

const SpainMapSvg = (props: SpainMapSvgProps) => {
  const idConvocatorias = useId()
  const [isLoading, setIsLoading] = useState(false)
  const [oposiciones, setOposiciones] = useState<
    TOposicionesConvocatoriaResponse['data']['attributes']['oposiciones']['data']
  >([])
  const [convocatorias, setConvocatorias] = useState<
    TConvocatoriasResponse['data']
  >([])
  const [province, setProvince] = useState('')

  useEffect(() => {
    const fn = async () => {
      setIsLoading(true)
      try {
        const { data: convocatoriasData } = await getConvocatorias()
        setConvocatorias(convocatoriasData)
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false)
    }
    fn()
  }, [])

  /*   useEffect(() => {
    if (!convocatorias.length) return
    const container = document.querySelector(`#mapa_convocatorias`)
    const coordsContainer = container!.getBoundingClientRect()
    convocatorias.map((convocatoria) => {
      let provincia = convocatoria.attributes.provincia
      const firstLetter = provincia[0].toUpperCase()
      const rest = provincia.slice(1)
      provincia = firstLetter + rest

      const path = document.querySelector(
        `#mapa_convocatorias path[name=${provincia}]`
      )
      if (!path) return
      const coords = path.getBoundingClientRect()
      const x = coords.x - coordsContainer.x + coords.width / 2
      const y = coords.y - coordsContainer.y + coords.height / 2
      const img = document.querySelector(
        `#mapa_convocatorias img[data-provincia=${provincia}]`
      ) as HTMLImageElement
      if (!img) return
      img.style.left = `${x}px`
      img.style.top = `${y}px`

      img.onclick = () => {
        props.onSelectProvince(convocatoria.id)
      }
    })
  }, [convocatorias]) */

  /* 
  useEffect(() => {
    const fn = async () => {
      setIsLoading(true)
      try {
        const convocatoria = convocatorias.find(
          (convocatoria) => convocatoria.attributes.provincia === province
        )
        window.scrollTo({
          top: document.getElementById(idConvocatorias)?.offsetTop || 0,
          behavior: 'smooth'
        })
        if (convocatoria) {
          const { data: oposicionesData } =
            await getOposicionesFromConvocatoria(convocatoria.id)
          setOposiciones(oposicionesData.attributes.oposiciones.data)
        } else {
          setOposiciones([])
        }
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false)
    }
    fn()
  }, [province]) */

  return (
    <div className="p-4">
      <div id="mapa_convocatorias" className={`${classes.container} mb-8`}>
        <style>{`
          ${convocatorias
            .map((convocatoria) => {
              return `.${classes.container} svg path[name="${convocatoria.attributes.provincia}"]`
            })
            .join(',')} {
            fill: #dbadad;
          }
        `}</style>
        <VectorMap
          {...spain}
          layerProps={{
            onClick: (e) => {
              const path = e.target as SVGPathElement
              const _provincia = path.getAttribute('name')
              if (_provincia) props.onSelectProvince(null)

              const convocatoria = convocatorias.find(
                (convocatoria) =>
                  convocatoria.attributes.provincia === _provincia
              )

              if (convocatoria) props.onSelectProvince(convocatoria.id)

              path.dataset.selected = 'true'
              document.querySelectorAll('path').forEach((path) => {
                path.dataset.selected = 'false'
              })
            }
          }}
        />
        {/*         {convocatorias.map((convocatoria) => {
          let provincia = convocatoria.attributes.provincia
          const firstLetter = provincia[0].toUpperCase()
          const rest = provincia.slice(1)
          provincia = firstLetter + rest
          return (
            <img
              key={convocatoria.id}
              src={`${BASE_URL}${convocatoria.attributes.logo.data.attributes.url}`}
              data-provincia={provincia}
              alt=""
            />
          )
        })} */}
      </div>
    </div>
  )
}

export default SpainMapSvg
