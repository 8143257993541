import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { OposicionQueryResponse } from 'utils/axios-sdk'

type Props = {
  enabled?: boolean
  oposicionId?: number
  variables?: object
}

export const oposicionQuery = (oposicionId?: number, params?: object) => {
  return axiosClient.get<{ data: OposicionQueryResponse }>(
    '/oposiciones/' + oposicionId,
    { params }
  )
}

const useOposicionQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['oposicion', props.oposicionId, props.variables],
    queryFn: async ({ queryKey }) => {
      return oposicionQuery(queryKey[1] as number, queryKey[2] as object)
    }
  })
}

export default useOposicionQuery
