import React from 'react'

const initialState = {
  page: 1,
  pageSize: 10,
  pageCount: 0
}

const usePagination = (props?: { initialState: typeof initialState }) => {
  const [pagination, setPagination] = React.useState(
    props?.initialState ?? initialState
  )

  const onPageChange = (page: number) => {
    setPagination((prev) => ({ ...prev, page }))
  }

  const resetPagination = () => {
    setPagination(props?.initialState ?? initialState)
  }

  const setPageCount = (value: number) => {
    setPagination((prev) => ({ ...prev, pageCount: value }))
  }

  const next = () => {
    setPagination((prev) => {
      if (prev.page === prev.pageCount) return prev
      return { ...prev, page: prev.page + 1 }
    })
  }

  const back = () => {
    setPagination((prev) => {
      if (prev.page === 1) return prev
      return { ...prev, page: prev.page - 1 }
    })
  }

  const last = () => {
    setPagination((prev) => ({ ...prev, page: prev.pageCount }))
  }

  const first = () => {
    setPagination((prev) => ({ ...prev, page: 1 }))
  }

  return {
    state: pagination,
    next,
    back,
    last,
    first,
    setPageCount,
    onPageChange,
    resetPagination
  }
}

export default usePagination
