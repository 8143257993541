import { useMutation } from '@tanstack/react-query'
import { blankQuestionsQuery } from 'hooks/query/use-blank-questions.query'
import { useStore } from 'store'
import { blankQuestionsMutation } from './use-blank-ask.mutation'
import { updateBlankQuestion } from './use-update-blank-question.mutation'
import { failedQuestionsMutation } from './use-failed-ask.mutation'
import { updateFailedQuestion } from './use-update-failed-question.mutation'
import {
  OposicionesResponsedMutationVariables,
  oposicionesResponsedMutation
} from './use-oposiciones-responsed.mutation'
import { failedQuestionsQuery } from 'hooks/query/use-failed-questions.query'
import { useExamStore } from 'store/exam.store'
import { retoQuery } from 'hooks/query/use-reto.query'
import { updateRetoMutation } from './use-update-reto.mutation'

export interface UseSaveExamVariables
  extends OposicionesResponsedMutationVariables {
  blankQuestions: string[]
  failedQuestions: string[]
}

const useSaveExam = () => {
  const { user } = useStore()

  return useMutation({
    mutationFn: async ({
      blankQuestions,
      failedQuestions,
      ...rest
    }: UseSaveExamVariables) => {
      const { type, challengeId, selectedAnswers } = useExamStore.getState()

      const questionPayload = {
        populate: 'asks',
        filters: {
          users_permissions_user: { id: { $eq: user?.id } }
        }
      }

      const blank = await blankQuestionsQuery(questionPayload)
      if (!blank.data.data) {
        throw new Error('Error fetching blank questions')
      }

      if (blank?.data?.data?.length === 0) {
        const blankRes = await blankQuestionsMutation({
          asks: blankQuestions,
          users_permissions_user: String(user?.id)
        })

        if (!blankRes?.data?.data?.id) {
          throw new Error('Error saving blank questions')
        }
      } else {
        const updateBlank = blank?.data?.data?.[0]
        const payload =
          updateBlank?.attributes?.asks?.data.map((ask) => String(ask.id)) ?? []

        let asksInput = [...blankQuestions, ...payload]

        if (type === 'blank' && selectedAnswers.length > 0) {
          const selectedAsks = selectedAnswers.map((ask) => String(ask.askId))
          asksInput = asksInput.filter((ask) => !selectedAsks.includes(ask))
        }

        const updateBlankRes = await updateBlankQuestion({
          blankQuestionId: String(updateBlank.id),
          users_permissions_user: String(user?.id),
          asks: asksInput
        })

        if (!updateBlankRes?.data?.data?.id) {
          throw new Error('Error updating blank questions')
        }
      }

      const failed = await failedQuestionsQuery(questionPayload)

      if (!failed.data.data) {
        throw new Error('Error fetching failed questions')
      }

      if (failed?.data?.data?.length === 0) {
        const failedRes = await failedQuestionsMutation({
          asks: failedQuestions,
          users_permissions_user: String(user?.id)
        })

        if (!failedRes?.data?.data?.id) {
          throw new Error('Error saving failed questions')
        }
      } else {
        const updateFailed = failed?.data?.data?.[0]
        const payload =
          updateFailed?.attributes?.asks?.data.map((ask) => String(ask.id)) ??
          []

        let asksInput = [...failedQuestions, ...payload]

        if (type === 'failed' && selectedAnswers.length > 0) {
          const selectedAsks = selectedAnswers
            .filter((ask) => ask.marked === ask.correct)
            .map((ask) => String(ask.askId))

          asksInput = asksInput.filter((ask) => !selectedAsks.includes(ask))
        }

        const updateFailedRes = await updateFailedQuestion({
          failedQuestionId: String(updateFailed.id),
          users_permissions_user: String(user?.id),
          asks: asksInput
        })

        if (!updateFailedRes?.data?.data?.id) {
          throw new Error('Error updating failed questions')
        }
      }

      const res = await oposicionesResponsedMutation(rest)
      if (!res?.data?.data?.id) {
        throw new Error('Error saving exam')
      }

      if (type === 'retos' && challengeId) {
        const retoRes = await retoQuery(Number(challengeId), {
          populate: 'participantes'
        })

        if (!retoRes?.data?.data?.id) {
          throw new Error('Error fetching reto')
        }

        const participantes =
          retoRes?.data?.data?.attributes?.participantes?.data.map(
            (participante) => String(participante.id)
          ) ?? []

        const updateRetoRes = await updateRetoMutation({
          retoId: String(retoRes.data.data.id),
          participantes: [...participantes, String(user?.id)],
          resultados: [
            ...retoRes.data.data.attributes.resultados,
            {
              id: Number(user?.id),
              username: user?.username ?? '',
              time: res.data.data.attributes.time,
              opositionResponse: res.data.data.id,
              average: res.data.data.attributes.average,
              asksAndResponses: selectedAnswers
            }
          ]
        })

        if (!updateRetoRes?.data?.data?.id) {
          throw new Error('Error updating reto')
        }
      }

      return res
    }
  })
}

export default useSaveExam
