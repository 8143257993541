import { cn } from 'utils/cn'
import { Card } from '../card/card'
import { Skeleton } from '../skeleton/skeleton'
import useAskQuery from 'hooks/query/use-ask.query'
import { useMemo } from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../accordion/accordion'
import { BASE_URL } from 'utils/axios-client'
import { SelectedAnswerType } from 'store/exam.store'

export interface RetoCardAskProps extends SelectedAnswerType {
  idx: number
  showExplanation?: boolean
}

export const RetoCardAsk = ({
  idx,
  askId,
  marked,
  correct,
  showExplanation
}: RetoCardAskProps) => {
  const { data, isLoading } = useAskQuery({
    askId,
    enabled: Boolean(askId),
    variables: { populate: 'img' }
  })

  const rightAnswer = correct
  const ask = data?.data.data.attributes

  const asks = useMemo(() => {
    const payload = [
      {
        id: 'A',
        children: ask?.responseA,
        hasChildren: !!ask?.responseA
      },
      {
        id: 'B',
        children: ask?.responseB,
        hasChildren: !!ask?.responseB
      },
      {
        id: 'C',
        children: ask?.responseC,
        hasChildren: !!ask?.responseC
      },
      {
        id: 'D',
        children: ask?.responseD,
        hasChildren: !!ask?.responseD
      }
    ] as const

    const skeletons = (['A', 'B', 'C', 'D'] as const).map((id) => ({
      id,
      hasChildren: true,
      children: <Skeleton className="w-full h-5" />
    }))

    return isLoading ? skeletons : payload.filter((item) => item.hasChildren)
  }, [
    isLoading,
    ask?.responseA,
    ask?.responseB,
    ask?.responseC,
    ask?.responseD
  ])

  const getStyles = (id: string) => {
    if (marked === id && rightAnswer === id) {
      return 'border-green-500 dark:border-green-700  rounded-md'
    }

    if (marked === id && rightAnswer !== id) {
      return 'border-red-500 dark:border-red-700  rounded-md'
    }

    if (marked !== id && rightAnswer === id) {
      return 'border-green-300 dark:border-green-700 rounded-md'
    }

    return 'border-b-slate-300 dark:border-b-slate-700 last:border-b-0'
  }

  return (
    <Card
      className={cn('px-4 mb-3 cursor-default', {
        'py-6': !showExplanation,
        'pt-6 pb-3': showExplanation
      })}
    >
      <div className="mb-3 font-bold flex gap-3">
        {!isLoading ? (
          <>
            <span>{idx > 10 ? idx : `0${idx}`}.</span>
            <span>{ask?.description}</span>
          </>
        ) : (
          <Skeleton className="w-full h-5" />
        )}
      </div>

      {BASE_URL && ask?.img?.data?.attributes?.url && (
        <img
          alt={ask?.description}
          className="w-full aspect-[16_/_9] rounded-md"
          src={(BASE_URL ?? '') + ask?.img?.data?.attributes?.url}
        />
      )}

      <ul className="flex flex-col">
        {asks.map(({ id, children }, index) => (
          <li
            key={index}
            className={cn('w-full border border-transparent', getStyles(id))}
          >
            <div className="flex w-full gap-3 items-center py-3 pl-2 text-left">
              <span className="w-8 h-8 grid place-items-center border dark:border-slate-700 rounded-full">
                {String.fromCharCode(65 + index)}
              </span>
              <span className="flex-1">{children}</span>
            </div>
          </li>
        ))}
      </ul>

      {showExplanation && (
        <Accordion type="single" collapsible>
          <AccordionItem
            value="item-1"
            className="border-b-transparent dark:border-b-transparent px-2"
          >
            <AccordionTrigger>¿Ver explicación?</AccordionTrigger>
            <AccordionContent>{ask?.explicacionRespuesta}</AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </Card>
  )
}
