import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from 'components/shared/dropdown-menu/dropdown-menu'
import Alert from 'components/shared/alert/alert'
import { Slider } from 'components/shared/slider/slider'
import dayjs from 'dayjs'
import useToggle from 'hooks/use-toggle'
import { motion } from 'framer-motion'
import {
  AArrowDownIcon,
  AArrowUpIcon,
  CaseSensitiveIcon,
  ClipboardXIcon,
  ClockIcon,
  MoreVertical,
  PauseIcon,
  PlayIcon,
  SaveAllIcon,
  SaveIcon
} from 'lucide-react'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import Button from 'shared/Button/Button'
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode'
import { useExamStore } from 'store/exam.store'
import { useNavigate, useParams } from 'react-router-dom'
import { CardAsk } from 'components/shared/card-asks/card-asks'
import { useStore } from 'store'
import { toast } from 'react-toastify'
import useSaveExam from 'hooks/mutation/use-save-exam.mutation'

type Props = {}

const initialState = {
  time: 0,
  showTime: true,
  play: true
}

type State = typeof initialState
type Action =
  | { type: 'SET_TIME'; payload: number }
  | { type: 'SHOW_TIME'; payload: boolean }
  | { type: 'TOGGLE_SHOW_TIME' }
  | { type: 'INCREMENT_TIME' }
  | { type: 'RESTART_TIME' }
  | { type: 'TOGGLE_PLAY' }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_TIME':
      return { ...state, time: action.payload }
    case 'SHOW_TIME':
      return { ...state, showTime: action.payload }
    case 'TOGGLE_SHOW_TIME':
      return { ...state, showTime: !state.showTime }
    case 'INCREMENT_TIME':
      return { ...state, time: state.time + 1000 }
    case 'RESTART_TIME':
      return { ...state, time: 0 }
    case 'TOGGLE_PLAY':
      return { ...state, play: !state.play }

    default:
      return state
  }
}

const ListAsks = (props: Props) => {
  const { user } = useStore()
  const { id } = useParams()
  const navigate = useNavigate()
  const sheetToggle = useToggle()
  const { mutate, isPending } = useSaveExam()
  const [fontSize, setFontSize] = useState(16)
  const sheetRef = useRef<BottomSheetRef>(null)
  const timeRef = useRef<NodeJS.Timeout | null>(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  const { sliceAsks, type, numberOfAsks, selectedAnswers, set, reset } =
    useExamStore((state) => ({
      type: state.type,
      sliceAsks: state.sliceAsks,
      numberOfAsks: state.numberOfAsks,
      selectedAnswers: state.selectedAnswers,
      set: state.set,
      reset: state.reset
    }))

  useEffect(() => {
    timeRef.current = setInterval(() => {
      dispatch({ type: 'INCREMENT_TIME' })
    }, 1000)

    return () => {
      if (timeRef.current) {
        console.log('BLUR')
        clearInterval(timeRef.current)
      }
    }
  }, [])

  const finish = async () => {
    if (!id) return
    if (!user?.id) return
    if (!type) return

    if (timeRef.current) clearInterval(timeRef.current)

    const blank = sliceAsks.length - selectedAnswers.length
    const correct = selectedAnswers.filter(
      ({ marked, correct }) => marked === correct
    ).length

    const fail = selectedAnswers.filter(
      ({ marked, correct }) => marked !== correct
    ).length

    const average = Number(((correct / sliceAsks.length) * 10).toFixed(1))

    mutate(
      {
        average,
        oposicion: id,
        typeTest: type,
        user: String(user?.id),
        asksAndResponses: [
          ...selectedAnswers,
          ...sliceAsks
            .filter(
              (key) =>
                !selectedAnswers.find(
                  ({ askId }) => askId === Number(key)
                ) as any
            )
            .map((key) => ({
              askId: Number(key),
              marked: null,
              correct: null
            }))
        ],
        asks: sliceAsks.map((askId) => String(askId)),
        time: dayjs.duration(state.time).format('HH:mm:ss'),
        blankQuestions: sliceAsks
          .filter(
            (key) => !selectedAnswers.find(({ askId }) => askId === Number(key))
          )
          .map((key) => String(key)),
        failedQuestions: selectedAnswers
          .filter(({ marked, correct }) => marked !== correct)
          .map(({ askId }) => String(askId))
      },
      {
        onSuccess: () => {
          toast.success('Respuestas guardadas correctamente')
          set({
            average,
            view: 'resume',
            time: state.time,
            failAnswers: fail,
            blankAnswers: blank,
            correctAnswers: correct
          })
        },
        onError: (error) => {
          console.log('[ERROR_SAVE_EXAM]: ', error)
          toast.error('Error guardando respuestas')
        }
      }
    )
  }

  const saveAndClose = () => {
    navigate('/convocatorias')
  }

  const discartAndClose = () => {
    reset()
    navigate('/convocatorias')
  }

  return (
    <>
      {/* HEADER */}
      <div className="sticky top-[81px] z-50 backdrop-blur-md dark:bg-neutral-900/50 py-2">
        <header className="flex items-center gap-1 justify-end">
          <Button
            onClick={() => dispatch({ type: 'TOGGLE_SHOW_TIME' })}
            className="text-base flex items-center gap-1 bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10 rounded-md h-12 px-3"
          >
            {state.showTime && dayjs.duration(state.time).format('HH:mm:ss')}
            <ClockIcon size={24} />
          </Button>
          <Button
            className="text-base flex items-center gap-1 bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10 rounded-md h-12 px-3"
            onClick={() => {
              if (state.play) {
                if (timeRef.current) {
                  clearInterval(timeRef.current)
                }
              } else {
                timeRef.current = setInterval(() => {
                  dispatch({ type: 'INCREMENT_TIME' })
                }, 1000)
              }

              dispatch({ type: 'TOGGLE_PLAY' })
            }}
          >
            {!state.play ? <PlayIcon size={24} /> : <PauseIcon size={24} />}
          </Button>
          <Button
            onClick={finish}
            disabled={isPending}
            className="text-base hidden md:flex items-center gap-1 bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10 rounded-md h-12 px-3"
          >
            {isPending ? 'Cargando...' : 'Terminar y corregir'}
          </Button>
          {/* MOBILE */}
          <Button
            onClick={sheetToggle.open}
            className="text-base flex md:hidden items-center gap-1 bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10 rounded-md h-12 px-3"
          >
            <MoreVertical size={24} />
          </Button>

          {/* DESKTOP */}
          <DropdownMenu>
            <DropdownMenuTrigger className="text-base hidden md:flex items-center gap-1 bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10 rounded-md h-12 px-3">
              <MoreVertical size={24} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                className="p-3 flex items-center gap-3"
                onClick={saveAndClose}
              >
                <SaveAllIcon />
                Guardar y salir
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={discartAndClose}
                className="p-3 flex items-center gap-3"
              >
                <ClipboardXIcon />
                Descartar y salir
              </DropdownMenuItem>
              <DropdownMenuItem className="p-3 flex items-center gap-3">
                <SwitchDarkMode className="w-6 h-6" />
                <p>Tema</p>
              </DropdownMenuItem>
              <div className="p-3 gap-3 flex items-center">
                <CaseSensitiveIcon size={24} />
                <Slider
                  min={12}
                  max={24}
                  step={1}
                  className="flex-1"
                  defaultValue={[16]}
                  onValueChange={([value]) => setFontSize(value)}
                />
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </header>

        <p className="px-3">
          {Math.floor((selectedAnswers.length * 100) / (numberOfAsks ?? 0))}%
        </p>
        <div className="w-full h-1 bg-slate-700 mb-6 rounded overflow-hidden">
          <motion.div
            initial={{ width: '0%' }}
            animate={{
              width: (selectedAnswers.length * 100) / (numberOfAsks ?? 0) + '%'
            }}
            className="h-1 bg-white"
          />
        </div>
      </div>

      <div style={{ fontSize }}>
        {sliceAsks.map((askId, idx) => (
          <CardAsk key={askId} idx={idx + 1} askId={Number(askId)} />
        ))}
      </div>

      {/* ALERT PAUSE */}
      <Alert
        isOpen={!state.play}
        title="Test pausado"
        acceptLabel="Reanudar"
        description="El test se ha pausado, puedes reanudarlo cuando quieras."
        overlayClassName="backdrop-filter backdrop-blur-lg bg-opacity-30"
        onAccept={() => {
          timeRef.current = setInterval(() => {
            dispatch({ type: 'INCREMENT_TIME' })
          }, 1000)

          dispatch({ type: 'TOGGLE_PLAY' })
        }}
      />

      {/* BOTTOM SHEET */}
      <BottomSheet
        ref={sheetRef}
        open={sheetToggle.isOpen}
        onDismiss={sheetToggle.close}
        snapPoints={() => [360]}
      >
        <button
          onClick={finish}
          disabled={isPending}
          className="px-3 py-4 flex items-center gap-3 mt-3"
        >
          <SaveIcon />
          {isPending ? 'Cargando...' : 'Terminar y corregir'}
        </button>
        <div className="h-[1px] w-full bg-slate-300 dark:bg-slate-800" />
        <button
          onClick={saveAndClose}
          className="px-3 py-4 flex items-center gap-3"
        >
          <SaveAllIcon />
          Guardar y salir
        </button>
        <div className="h-[1px] w-full bg-slate-300 dark:bg-slate-800" />
        <button
          onClick={discartAndClose}
          className="px-3 py-4 flex items-center gap-3"
        >
          <ClipboardXIcon />
          Descartar y salir
        </button>
        <div className="h-[1px] w-full bg-slate-300 dark:bg-slate-800" />
        <div className="px-3 py-4 flex items-center justify-between">
          <p>Tema</p>
          <SwitchDarkMode className="w-6 h-6" />
        </div>
        <div className="h-[1px] w-full bg-slate-300 dark:bg-slate-800" />
        <div className="p-3">
          <p className="mb-1">Tamaño de fuente</p>
          <div className="flex gap-3">
            <AArrowDownIcon />
            <Slider
              min={12}
              max={32}
              step={1}
              defaultValue={[16]}
              onValueChange={([value]) => setFontSize(value)}
            />
            <AArrowUpIcon />
          </div>
        </div>
      </BottomSheet>
    </>
  )
}

export default ListAsks
