import { useState } from 'react'

const useToggle = (initialState?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialState ?? false)

  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen((prev) => !prev)
  }
}

export default useToggle
