import { Card } from 'components/shared/card/card'
import dayjs from 'dayjs'
import useRetosQuery from 'hooks/query/use-retos.query'
import { ChevronRightIcon, Loader2Icon } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'

type Props = {}

const TabPersonal = (props: Props) => {
  const { user } = useStore()
  const navigate = useNavigate()

  const retosQuery = useRetosQuery({
    enabled: !!user?.id,
    variables: {
      sort: 'createdAt:desc',
      populate: 'creador,oposicion,temario',
      filters: {
        type: { $eq: 'personal' },
        participantes: { id: { $eq: user?.id } }
      }
    }
  })

  return (
    <>
      {retosQuery.isLoading && (
        <div className="w-full h- grid place-items-center pt-20">
          <Loader2Icon className="animate-spin" />
        </div>
      )}

      {!retosQuery.isLoading && (
        <>
          {retosQuery.data?.data?.data?.map((reto) => {
            const isOposicion = !!reto?.attributes?.oposicion?.data?.id
            return (
              <Card
                key={reto.id}
                className="p-3 md:p-5 mt-3 space-y-1"
                onClick={() => navigate(`/retos/${reto.id}`)}
              >
                <p className="font-semibold">
                  {isOposicion
                    ? reto?.attributes?.oposicion?.data?.attributes?.nombre
                    : reto?.attributes?.temario?.data?.attributes?.title}
                </p>
                <p className="text-sm text-slate-600 dark:text-slate-400">
                  Tipo: {isOposicion ? 'Oposición' : 'Temario'}
                </p>
                <p className="text-sm text-slate-600 dark:text-slate-400">
                  Creador:{' '}
                  {reto?.attributes?.creador?.data?.attributes?.username}
                </p>
                <div className="flex items-center justify-between">
                  <p className="text-sm text-slate-600 dark:text-slate-400">
                    Fecha:{' '}
                    {dayjs(reto?.attributes?.createdAt).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </p>

                  <ChevronRightIcon className="w-5 h-5" />
                </div>
              </Card>
            )
          })}
        </>
      )}
    </>
  )
}

export { TabPersonal }
