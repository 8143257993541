import { OficialTestQueryResponse, RetoQueryResponse } from 'utils/axios-sdk'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type CorrectionType = 'after' | 'instant'
export type AnswerType = 'A' | 'B' | 'C' | 'D'
export type ViewType =
  | 'correction'
  | 'list-asks'
  | 'resume'
  | 'challenge-type'
  | 'join-challenge'
  | 'generate-challenge'
  | 'share-challenge'

export type ExamType = 'personalized' | 'failed' | 'blank' | 'oficial' | 'retos'

export type ChallengeType = 'personal' | 'community'

export type SelectedAnswerType = {
  askId: number
  marked: AnswerType | null
  correct: AnswerType | null
}

export interface ExamStoreState {
  time: number
  average: number
  recordAlias: Record<number, string>
  view: ViewType | null
  type: ExamType | null
  failAnswers: number | null
  blankAnswers: number | null
  correctAnswers: number | null
  correction: CorrectionType | null
  selectedTopics: number[]
  numberOfAsks: number | null
  asks: Record<number, number[]>
  topics: Record<number, number[]>
  selectedAnswers: Array<SelectedAnswerType>
  oficialExams: OficialTestQueryResponse[]
  sliceAsks: number[]
  challengeId: string | null
  challengeSlug: string | null
  challengeType: ChallengeType | null
  challengeCreatorId?: number
}

export interface ExamStoreAction {
  set: (
    stateOrCallback:
      | Partial<ExamStoreState>
      | ((state: ExamStoreState) => Partial<ExamStoreState>)
  ) => void
  reset: () => void
  loadRetoData: (reto: RetoQueryResponse) => void
}

const initialState: ExamStoreState = {
  asks: {},
  topics: {},
  recordAlias: {},
  type: null,
  view: null,
  time: 0,
  average: 0,
  failAnswers: null,
  blankAnswers: null,
  correctAnswers: null,
  correction: null,
  numberOfAsks: null,
  selectedTopics: [],
  selectedAnswers: [],
  oficialExams: [],
  sliceAsks: [],
  challengeId: null,
  challengeType: null,
  challengeSlug: null
}

export const useExamStore = create(
  persist<ExamStoreState & ExamStoreAction>(
    (set) => ({
      ...initialState,
      set: (stateOrCallback) => {
        if (typeof stateOrCallback === 'function') {
          set((state) => ({ ...state, ...stateOrCallback(state) }))
          return
        }

        set(stateOrCallback)
      },
      reset: () => set(initialState),
      loadRetoData: (reto) => {
        set(initialState)

        const askIds: Record<number, number[]> = {}
        const topicIds: Record<number, number[]> = {}
        const allAsks = reto.attributes.asks?.data ?? []

        for (const ask of allAsks) {
          if (typeof ask.id === 'number') {
            const topics = ask.attributes.temas?.data ?? []
            askIds[ask.id] = topics.map((tema) => tema.id)

            for (const topic of topics) {
              if (typeof topic.id === 'number') {
                if (!topicIds[topic.id]) {
                  topicIds[topic.id] = []
                }

                topicIds[topic.id].push(ask.id)
              }
            }
          }
        }

        set({
          type: 'retos',
          view: 'join-challenge',
          challengeId: String(reto.id),
          challengeSlug: reto.attributes.slug,
          challengeType: reto.attributes.type,
          challengeCreatorId: reto.attributes.creador?.data.id,
          correction: reto.attributes.typeCorrection,
          numberOfAsks: reto.attributes.asks?.data.length,
          sliceAsks: reto.attributes.asks?.data.map((ask) => ask.id),
          recordAlias: reto.attributes.recordAlias ?? {},
          asks: askIds,
          topics: topicIds,
          selectedTopics: Object.keys(topicIds).map(Number)
        })
      }
    }),
    {
      name: 'exam-store',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
