import axios from 'axios'
import { BASE_API_URL } from 'api'
import { TLoginResponse, TRegisterResponse } from './types'

export const postLogin = async (body: {
  identifier: string
  password: string
}) => {
  const response = await axios.post(`${BASE_API_URL}/auth/local`, body)
  return response.data as TLoginResponse
}

export const postRegister = async (body: {
  username: string
  email: string
  password: string
}) => {
  const response = await axios.post(`${BASE_API_URL}/auth/local/register`, body)
  return response.data as TRegisterResponse
}

export * from './types'
