import { Loader2 } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { ExamType, useExamStore } from 'store/exam.store'
import { oposicionQuery } from 'hooks/query/use-oposicion.query'

import SelectTopics from './select-topics'
import SelectExamType from './select-exam-type'
import CorrectionType from './correction-type'
import ListAsks from './list-asks'
import Resume from './resume'
import { failedQuestionsQuery } from 'hooks/query/use-failed-questions.query'
import { blankQuestionsQuery } from 'hooks/query/use-blank-questions.query'
import { oficialTestQuery } from 'hooks/query/use-oficial-test.query'
import SelectOficialExam from './select-oficial-exam'
import SelectChallengeType from './select-challenge-type'
import JoinChallenge from './join-challenge'
import ShareChallenge from './share-challenge-type'
import GenerateChallenge from './generate-challenge'
import { useStore } from 'store'

const Oposicion = () => {
  const { id } = useParams()
  const { type, view } = useExamStore((state) => ({
    type: state.type,
    view: state.view
  }))

  const { isLoading } = useQuery({
    enabled: id !== undefined && type !== null,
    queryKey: ['exam-data', Number(id), type],
    queryFn: async ({ queryKey }) => {
      const { set } = useExamStore.getState()
      const type = queryKey[2] as ExamType | null

      set({ topics: {}, asks: {} })

      if (type === 'oficial') {
        const oficialTestRes = await oficialTestQuery({
          populate: 'asks,asks.temas',
          filters: { oposiciones: { id: { $eq: queryKey[1] as number } } }
        })

        if (!Array.isArray(oficialTestRes?.data?.data)) {
          throw new Error('Error fetching oficial test')
        }

        const oficialExams = oficialTestRes.data.data
        const askIds: Record<number, number[]> = {}
        const topicIds: Record<number, number[]> = {}
        const allAsks = oficialExams
          .map((topic) => topic.attributes.asks?.data)
          .flat()

        for (const ask of allAsks) {
          if (typeof ask?.id === 'number') {
            const topics = ask.attributes.temas?.data ?? []
            askIds[ask.id] = topics.map((tema) => tema.id)

            for (const topic of topics) {
              if (typeof topic.id === 'number') {
                if (!topicIds[topic.id]) {
                  topicIds[topic.id] = []
                }

                topicIds[topic.id].push(ask.id)
              }
            }
          }
        }

        set({ oficialExams, asks: askIds, topics: topicIds })

        return oficialTestRes.data.data
      }

      const res = await oposicionQuery(queryKey[1] as number, {
        populate: 'asks,asks.temas'
      })

      if (!res?.data?.data?.id) {
        throw new Error('Error fetching oposicion')
      }

      const user = useStore.getState()?.user
      const askIds: Record<number, number[]> = {}
      const topicIds: Record<number, number[]> = {}
      const allAsks = res.data?.data?.attributes?.asks?.data ?? []

      for (const ask of allAsks) {
        if (typeof ask.id === 'number') {
          const topics = ask.attributes.temas?.data ?? []
          askIds[ask.id] = topics.map((tema) => tema.id)

          for (const topic of topics) {
            if (typeof topic.id === 'number') {
              if (!topicIds[topic.id]) {
                topicIds[topic.id] = []
              }

              topicIds[topic.id].push(ask.id)
            }
          }
        }
      }

      if (user?.id && type === 'failed') {
        const failed = await failedQuestionsQuery({
          populate: 'asks',
          filters: { users_permissions_user: { id: { $eq: user.id } } }
        })

        const failedAsks =
          failed?.data?.data?.[0]?.attributes?.asks?.data?.map(
            (ask) => ask.id
          ) ?? []

        for (const askId of Object.keys(askIds)) {
          if (!failedAsks.includes(Number(askId))) {
            delete askIds[Number(askId)]
          }
        }

        for (const topicId of Object.keys(topicIds)) {
          const asks = topicIds[Number(topicId)]
          const hasFilterAsk = asks.some((ask) => failedAsks.includes(ask))
          if (!hasFilterAsk) {
            delete topicIds[Number(topicId)]
          } else {
            topicIds[Number(topicId)] = asks.filter((ask) =>
              failedAsks.includes(ask)
            )
          }
        }
      }

      if (user?.id && type === 'blank') {
        const blank = await blankQuestionsQuery({
          populate: 'asks',
          filters: { users_permissions_user: { id: { $eq: user.id } } }
        })

        const blankAsks =
          blank?.data?.data?.[0]?.attributes?.asks?.data?.map(
            (ask) => ask.id
          ) ?? []

        for (const askId of Object.keys(askIds)) {
          if (!blankAsks.includes(Number(askId))) {
            delete askIds[Number(askId)]
          }
        }

        for (const topicId of Object.keys(topicIds)) {
          const asks = topicIds[Number(topicId)]
          const hasFilterAsk = asks.some((ask) => blankAsks.includes(ask))
          if (!hasFilterAsk) {
            delete topicIds[Number(topicId)]
          } else {
            topicIds[Number(topicId)] = asks.filter((ask) =>
              blankAsks.includes(ask)
            )
          }
        }
      }

      set({
        asks: askIds,
        topics: topicIds,
        recordAlias: res?.data?.data?.attributes?.recordAlias ?? {}
      })
      return res.data.data
    }
  })

  return (
    <div className="w-[90vw] max-w-5xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      {isLoading && (
        <div className="w-full h-full grid place-items-center flex-1">
          <Loader2 className="animate-spin" size={64} />
        </div>
      )}

      {!isLoading && (
        <>
          {type === null && view === null && <SelectExamType />}
          {type !== null && type !== 'oficial' && view === null && (
            <SelectTopics />
          )}
          {type !== null && type === 'oficial' && view === null && (
            <SelectOficialExam />
          )}

          {type !== null && view !== null && (
            <>
              {view === 'challenge-type' && <SelectChallengeType />}
              {view === 'generate-challenge' && <GenerateChallenge />}
              {view === 'join-challenge' && <JoinChallenge />}
              {view === 'share-challenge' && <ShareChallenge />}
              {view === 'correction' && <CorrectionType />}
              {view === 'list-asks' && <ListAsks />}
              {view === 'resume' && <Resume />}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Oposicion
