import { useMutation } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'

type FailedAskMutationVariables = {
  asks: string[]
  users_permissions_user: string
}

export const failedQuestionsMutation = (props: FailedAskMutationVariables) => {
  return axiosClient.post('/failed-questions', {
    data: props
  })
}

const useFailedQuestionsMutation = () => {
  return useMutation({ mutationFn: failedQuestionsMutation })
}

export default useFailedQuestionsMutation
