import { CircleIcon, EditIcon, SwordsIcon, XCircleIcon } from 'lucide-react'
import { useExamStore } from 'store/exam.store'
import { Card } from 'components/shared/card/card'

type Props = {}

const TIPOS_TEST = [
  {
    id: 'personalized',
    title: 'Personalizado',
    icon: EditIcon,
    description:
      'Configura tu propio test eligiendo el número de preguntas, temas y dificultad.'
  },
  {
    id: 'failed',
    title: 'Preguntas falladas',
    icon: XCircleIcon,
    description:
      'Repasa preguntas falladas en otros test para mejorar tus resultados.'
  },
  {
    id: 'blank',
    title: 'Preguntas en blanco',
    icon: CircleIcon,
    description:
      'Haz un nuevo intento con las preguntas que dejaste en blanco en otros test.'
  },
  {
    id: 'retos',
    title: 'Retos',
    icon: SwordsIcon,
    description:
      'Compite con otros opositores en retos de preguntas cronometrados.'
  }
] as const

const SelectExamType = (props: Props) => {
  const { set } = useExamStore((state) => ({
    set: state.set
  }))

  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">
          ¿Que tipo de test quieres hacer?
        </h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, fuga?
          Itaque autem pariatur deleniti dolorem necessitatibus dignissimos
          quam.
        </h3>
      </div>

      {TIPOS_TEST.map(({ id, title, description, icon: Icon }) => (
        <Card
          key={id}
          className="p-3 md:p-5 mb-3"
          onClick={() => set({ type: id })}
        >
          <div className="flex items-center gap-3 mb-1">
            <Icon />
            <h3 className="text-xl font-semibold mb-1">{title}</h3>
          </div>

          <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
            {description}
          </p>
        </Card>
      ))}
    </>
  )
}

export default SelectExamType
