import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { RetoQueryResponse } from 'utils/axios-sdk'

type Props = {
  retoId?: number
  enabled?: boolean
  variables?: object
}

export const retoQuery = (retoId?: number, params?: object) => {
  return axiosClient.get<{ data: RetoQueryResponse }>('/retos/' + retoId, {
    params
  })
}

const useRetoQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['reto', props.retoId, props.variables],
    queryFn: async ({ queryKey }) => {
      return retoQuery(queryKey[1] as number, queryKey[2] as object)
    }
  })
}

export default useRetoQuery
