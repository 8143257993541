import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { ConvocatoriaQueryResponse } from 'utils/axios-sdk'

type Props = {
  enabled?: boolean
  convocatoriaId?: number
  variables?: object
}

export const convocatoriaQuery = (convocatoriaId?: number, params?: object) => {
  return axiosClient.get<{ data: ConvocatoriaQueryResponse }>(
    '/convocatorias/' + convocatoriaId,
    { params }
  )
}

const useConvocatoriaQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['convocatoria', props.convocatoriaId, props.variables],
    queryFn: async ({ queryKey }) => {
      return convocatoriaQuery(queryKey[1] as number, queryKey[2] as object)
    }
  })
}

export default useConvocatoriaQuery
