import { useExamStore } from 'store/exam.store'
import { Card } from 'components/shared/card/card'

type Props = {}

const CorrectionType = (props: Props) => {
  const { set, type } = useExamStore((state) => ({
    type: state.type,
    set: state.set
  }))

  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">
          Tipo de corrección
        </h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Seleccione cuando quiere corregir las preguntas seleccionadas.
        </h3>
      </div>

      <Card
        className="p-3 md:p-5 mb-3"
        onClick={() => {
          set({
            correction: 'after',
            view: type === 'retos' ? 'challenge-type' : 'list-asks'
          })
        }}
      >
        <h3 className="text-xl font-semibold mb-1">
          Después de responder todo
        </h3>
        <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
          Quiero corregir después de responder todas las preguntas.
        </p>
      </Card>

      <Card
        className="p-3 md:p-5 mb-3"
        onClick={() => {
          set({
            correction: 'instant',
            view: type === 'retos' ? 'challenge-type' : 'list-asks'
          })
        }}
      >
        <h3 className="text-xl font-semibold mb-1">Cada ves que reponda</h3>
        <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
          Quiero corregir cada vez que responda una pregunta.
        </p>
      </Card>
    </>
  )
}

export default CorrectionType
