import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import { User } from 'utils/axios-sdk'

type Props = {
  userId?: number
  enabled?: boolean
  variables?: object
}

export const userQuery = (userId?: number, params?: object) => {
  return axiosClient.get<User>('/users/' + userId, {
    params
  })
}

const useUserQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['user', props.userId, props.variables],
    queryFn: async ({ queryKey }) => {
      return userQuery(queryKey[1] as number, queryKey[2] as object)
    }
  })
}

export default useUserQuery
