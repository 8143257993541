import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { OposicionesResponsedQueryResponse } from 'utils/axios-sdk'

type Props = {
  enabled?: boolean
  variables?: object
}

export const oposicionesResponsedQuery = (params?: object) => {
  return axiosClient.get<{ data: OposicionesResponsedQueryResponse[] }>(
    '/oposiciones-responseds',
    { params }
  )
}

const useOposicionesResponsedQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['oposiciones-responseds', props.variables],
    queryFn: async ({ queryKey }) => {
      return oposicionesResponsedQuery(queryKey[1] as object)
    }
  })
}

export default useOposicionesResponsedQuery
