import { FC } from 'react'
import Logo from 'shared/Logo/Logo'
import MenuBar from 'shared/MenuBar/MenuBar'
import Navigation from 'shared/Navigation/Navigation'
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode'

import { UserMenu } from 'components/shared/user-menu/user-menu'

export interface MainNav1Props {
  className?: string
}

const MainNav1: FC<MainNav1Props> = ({ className = '' }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="flex justify-start flex-1 items-center space-x-4 sm:space-x-20">
          <Logo />
          <Navigation />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden md:flex items-center space-x-0.5">
            <SwitchDarkMode />
            <UserMenu />
          </div>
        </div>

        <div className="flex md:hidden items-center ml-auto">
          <SwitchDarkMode />
          <div className="px-0.5" />

          <UserMenu />
          <MenuBar />
        </div>
      </div>
    </div>
  )
}

export default MainNav1
