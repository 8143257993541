import axios from 'axios'

export const BASE_URL = 'https://bomberos-app.cloud.desarrollosmoyan.com'
export const BASE_API_URL =
  'https://bomberos-app.cloud.desarrollosmoyan.com/api'

const client = axios.create({
  baseURL: 'https://bomberos-app.cloud.desarrollosmoyan.com/api',
  headers: {
    'Content-Type': 'application/json'
  }
})

client.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    if (!token) return config

    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export const axiosClient = client
