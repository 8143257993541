import { ExamStoreState, useExamStore } from 'store/exam.store'
import { Card } from 'components/shared/card/card'
import useTemaQuery from 'hooks/query/use-tema.query'
import { Skeleton } from 'components/shared/skeleton/skeleton'
import Button from 'shared/Button/Button'

import { useStore } from 'store'
import { updateRetoMutation } from 'hooks/mutation/use-update-reto.mutation'
import { useMutation } from '@tanstack/react-query'
import { retoQuery } from 'hooks/query/use-reto.query'
import { toast } from 'react-toastify'

type Props = {}

const ResumeTopicCard = ({ topicId }: { topicId?: number }) => {
  const recordAlias = useExamStore((state) => state.recordAlias)

  const { data, isLoading } = useTemaQuery({
    temaId: topicId,
    enabled: Boolean(topicId)
  })

  const topic = data?.data?.data?.attributes ?? null

  return (
    <Card key={topicId} className="p-3 md:p-5 mb-3">
      {isLoading && (
        <div className="w-full h-full">
          <div className="flex items-center gap-3 mb-2">
            <Skeleton className="flex-1 h-5" />
          </div>
          <Skeleton className="w-full h-5" />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="flex items-center gap-3">
            <h3 className="text-xl font-semibold">
              {recordAlias?.[topicId!] ?? topic?.title}
            </h3>
          </div>

          <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
            {topic?.description}
          </p>
        </>
      )}
    </Card>
  )
}

const JoinChallenge = (props: Props) => {
  const { user } = useStore()
  const store = useExamStore()

  const { mutate, isPending } = useMutation({
    mutationFn: async (values: ExamStoreState) => {
      if (!user?.id) {
        throw new Error('Debes iniciar sesión para unirte al reto')
      }

      if (!values.challengeId) {
        throw new Error('El ID del reto es requerido')
      }

      const reto = await retoQuery(Number(values.challengeId), {
        populate: 'participantes'
      })

      if (!reto?.data?.data?.id) {
        throw new Error('El reto no existe')
      }

      const hasMyParticipation =
        reto.data.data.attributes.participantes?.data?.some(
          (p) => p.id === user?.id
        )

      if (hasMyParticipation) {
        throw new Error('Ya estás participando en este reto')
      }

      const updateRes = await updateRetoMutation({
        retoId: values.challengeId!,
        participantes: [
          ...(reto?.data.data.attributes.participantes?.data.map((user) =>
            String(user.id)
          ) ?? []),
          String(user?.id)
        ]
      })

      return updateRes
    }
  })

  const handleSubmit = async () => {
    if (!user?.id) return

    mutate(store, {
      onSuccess: (res) => {
        if (!res?.data?.data?.id) {
          toast.error('Error al unirse al reto')
          return
        }

        toast.success('Te has unido al reto')
        store.set({ view: 'list-asks' })
      },
      onError: (error) => {
        toast.error(error.message)
      }
    })
  }

  return (
    <>
      <h2 className="text-xl md:text-3xl font-semibold mb-6">Unirse al reto</h2>

      <ul className="space-y-4 md:space-y-6">
        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">Temas</h3>
          {store.selectedTopics.map((topicId) => (
            <ResumeTopicCard key={topicId} topicId={topicId} />
          ))}
        </li>

        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">
            Número de preguntas
          </h3>
          <p className="text-neutral-500 dark:text-neutral-400">
            {store.numberOfAsks} preguntas
          </p>
        </li>

        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">
            Tipo de corrección
          </h3>
          <p className="text-neutral-500 dark:text-neutral-400">
            {store.correction === 'after'
              ? 'Después de responder todo'
              : 'Cada vez que responda'}
          </p>
        </li>

        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">
            Tipo de reto
          </h3>
          <p className="text-neutral-500 dark:text-neutral-400">
            {store.challengeType === 'personal'
              ? 'Reto personal'
              : 'Reto de comunidad'}
          </p>
        </li>
      </ul>

      <Button
        loading={isPending}
        onClick={handleSubmit}
        className="bg-black bg-opacity-10 dark:bg-white dark:bg-opacity-10 rounded-md mt-auto w-full sm:w-max ml-auto"
      >
        {isPending ? 'Cargando...' : 'Unirse'}
      </Button>
    </>
  )
}

export default JoinChallenge
