import dayjs from 'dayjs'
import { Echarts } from 'components/shared/echarts/echarts'
import { CardAsk } from 'components/shared/card-asks/card-asks'

import { useExamStore } from 'store/exam.store'
import { useEffect, useMemo } from 'react'
import Button from 'shared/Button/Button'
import { useNavigate } from 'react-router-dom'
import { getPieChartAskOptions } from 'utils/get-pie-chart-ask-options'

type Props = {}

const Resume = (props: Props) => {
  const state = useExamStore()
  const navigate = useNavigate()

  useEffect(() => {
    state.set({ correction: 'instant' })
  }, [state.set])

  return (
    <>
      <div className="mb-3">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">
          Resultado general
        </h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat,
          autem. Rem corporis, similique, illo sapiente voluptate ex eos dolore
          optio omnis eligendi incidunt.
        </h3>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2">
        <Echarts
          className="w-full aspect-1"
          option={useMemo(
            () =>
              getPieChartAskOptions({
                fail: state?.failAnswers ?? 0,
                blank: state?.blankAnswers ?? 0,
                correct: state?.correctAnswers ?? 0
              }),
            [state?.failAnswers, state?.blankAnswers, state?.correctAnswers]
          )}
        />

        <div className="flex justify-around items-center text-center mb-6">
          <div>
            <p>Nota final</p>
            <p className="font-bold"> {state?.average} / 10 </p>
          </div>

          <div>
            <p>Tiempo total</p>
            <p className="font-bold">
              {dayjs.duration(state?.time ?? 0).format('mm:ss')}
            </p>
          </div>
        </div>
      </div>

      <h2 className="text-xl md:text-3xl font-semibold mb-3">Preguntas</h2>

      {state?.selectedAnswers.map(({ askId }, idx) => (
        <CardAsk key={askId} askId={askId} idx={idx + 1} showExplanation />
      ))}

      <Button
        className="bg-black bg-opacity-10 dark:bg-white dark:bg-opacity-10 mt-6 rounded-md"
        onClick={() => {
          state.reset()
          navigate('/convocatorias')
        }}
      >
        Salir
      </Button>
    </>
  )
}

export default Resume
