import { useQuery } from '@tanstack/react-query'
import { Meta } from 'api'
import { axiosClient } from 'utils/axios-client'
import type { TemarioQueryResponse } from 'utils/axios-sdk'

type Data = {
  meta: Meta
  data: TemarioQueryResponse[]
}

type Props = {
  enabled?: boolean
  variables?: object
  onSuccess?: (data: Data) => void
}

export const temariosQuery = (params?: object) => {
  return axiosClient.get<Data>('/temarios', {
    params
  })
}

const useTemariosQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['temarios', props.variables],
    queryFn: async ({ queryKey }) => {
      const res = await temariosQuery(queryKey[1] as object)
      props.onSuccess?.(res.data)
      return res
    }
  })
}

export default useTemariosQuery
