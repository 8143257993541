import React from 'react'

type Props = {}

const NuevoTest = (props: Props) => {
  return (
    <div className="w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      NuevoTest
    </div>
  )
}

export default NuevoTest
