import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import * as echarts from 'echarts'

type Props = {
  className?: string
  option: echarts.EChartsOption
  use?: Parameters<typeof echarts.use>['0']
}

const Echarts = (props: Props) => {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props?.use) {
      echarts.use(props.use)
    }

    const myChart = echarts.init(divRef.current, 'dark')
    props?.option && myChart.setOption(props.option)

    return () => {
      myChart.dispose()
    }
  }, [props.option])

  return <div ref={divRef} className={clsx(props.className)} />
}

export { Echarts }
