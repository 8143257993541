import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Input from 'shared/Input/Input'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { postRegister } from 'api/auth'
import { useStore } from 'store'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export interface PageSignUpProps {
  className?: string
}

const PageSignUp: FC<PageSignUpProps> = ({ className = '' }) => {
  const { login, user } = useStore()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect') || '/'
  const navigate = useNavigate()

  const { mutate, isPending } = useMutation({
    mutationFn: postRegister
  })

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    if (
      !e.currentTarget.email.value ||
      !e.currentTarget.username.value ||
      !e.currentTarget.password.value
    ) {
      toast.error('Por favor, complete todos los campos.')
      return
    }

    mutate(
      {
        email: e.currentTarget.email.value,
        username: e.currentTarget.username.value,
        password: e.currentTarget.password.value
      },
      {
        onSuccess: (data) => {
          login(data.jwt, data.user)
        },
        onError: (error) => {
          const _error = error as AxiosError<{ error: { message: string } }>
          console.log('[ERROR_REGISTER]', error)

          const errorRecord: Record<string, string> = {
            'Email or Username are already taken': 'Correo o usuario ya existen'
          }

          if (
            _error?.response?.data?.error.message &&
            errorRecord[_error.response?.data?.error.message]
          ) {
            toast.error(errorRecord[_error.response?.data?.error.message])
            return
          }

          toast.error('Error desconocido, intente nuevamente.')
        }
      }
    )
  }

  useEffect(() => {
    if (!user) return
    const redirect = searchParams.get('redirect')
    navigate(redirect || '/')
  }, [user])

  return (
    <div
      className=" w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col justify-center mx-auto py-8 md:py-10"
      data-nc-id="PageSignUp"
    >
      <Helmet>
        <title>Registro</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Registro
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={onSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Usuario
              </span>
              <Input
                type="text"
                name="username"
                placeholder="Ingrese su usuario"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Correo
              </span>
              <Input
                type="email"
                name="email"
                placeholder="Ingrese su correo"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Contraseña
              </span>
              <Input
                type="password"
                name="password"
                className="mt-1"
                placeholder="Ingrese su contraseña"
              />
            </label>
            <ButtonPrimary type="submit" loading={isPending}>
              Registrarse
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Ya tiene una cuenta? {` `}
            <Link to={'/login?redirect=' + redirect} className="underline">
              Iniciar sesión
            </Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PageSignUp
