import { User } from 'utils/axios-sdk'
import { create } from 'zustand'

export type TStore = {
  jwt: string | null
  user: User | null
  logout: () => void
  login: (jwt: string, user: User) => void
}

export const useStore = create<TStore>((set) => ({
  jwt: null,
  user: null,
  login: (jwt, user) => {
    localStorage.setItem('token', jwt)
    set({ jwt, user })
  },
  logout: () => {
    localStorage.removeItem('token')
    set({ jwt: null, user: null })
  }
}))
