import { Loader2, SwordsIcon } from 'lucide-react'
import { Fragment, useState } from 'react'

import useConvocatoriaQuery from 'hooks/query/use-convocatoria-query'
import SpainMapSvg from 'components/shared/spain-map-svg/spain-map-svg'
import { Link } from 'react-router-dom'
import { useExamStore } from 'store/exam.store'
import { useStore } from 'store'

const Convocatorias = () => {
  const { user } = useStore()
  const [convocatoriaId, setConvocatoriaId] = useState<number | null>(null)

  const convocatoriasQuery = useConvocatoriaQuery({
    enabled: convocatoriaId !== null,
    variables: {
      populate: 'oposiciones.asks,oposiciones.asks.temas'
    },
    ...(convocatoriaId !== null && { convocatoriaId })
  })

  const convocatoria = convocatoriasQuery.data?.data?.data
  const oposiciones = convocatoria?.attributes?.oposiciones?.data ?? []
  const hasOposiciones = oposiciones?.length !== 0

  return (
    <div className="w-[90vw] max-w-4xl min-h-[calc(100dvh_-_169px)] flex flex-col justify-center mx-auto py-8 md:py-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <SpainMapSvg onSelectProvince={setConvocatoriaId} />

        <div className="w-full aspect-1">
          {convocatoriasQuery.isLoading && (
            <div className="w-full h-full grid place-items-center">
              <Loader2 className="animate-spin" size={64} />
            </div>
          )}

          {!convocatoriasQuery.isLoading && (
            <>
              {convocatoriaId === null && (
                <div className="w-full h-full grid place-items-center">
                  <h2 className="text-xl text-center md:text-3xl font-semibold">
                    Debe seleccionar una provincia
                  </h2>
                </div>
              )}

              {convocatoriaId !== null && !hasOposiciones && (
                <div className="w-full h-full grid place-items-center">
                  <h2 className="text-xl text-center md:text-3xl font-semibold">
                    No hay oposiciones para esta provincia
                  </h2>
                </div>
              )}

              {convocatoriaId !== null && hasOposiciones && (
                <div className="w-full h-full">
                  <h2 className="text-4xl font-semibold">
                    {convocatoria?.attributes.nombre}
                  </h2>
                  <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                    {oposiciones?.length} resultados
                  </span>

                  <div className="grid grid-cols-1 gap-8 mt-6">
                    {oposiciones?.map((item) => {
                      const temasDisponibles =
                        item?.attributes?.asks?.data?.reduce(
                          (acc, curr) =>
                            acc + (curr?.attributes?.temas?.data?.length ?? 0),
                          0
                        )

                      return (
                        <Link
                          key={item.id}
                          to={
                            user
                              ? `/oposicion/${item.id}`
                              : '/login?redirect=/oposicion/' + item.id
                          }
                          onClick={() => {
                            useExamStore.getState().reset()
                          }}
                          className="group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform cursor-pointer"
                        >
                          <div className="p-3 sm:p-5 space-y-2">
                            <h2 className="text-lg font-medium capitalize">
                              <span className="line-clamp-1">
                                {item.attributes.nombre}
                              </span>
                            </h2>
                            <div className="flex-grow flex gap-6">
                              <div className="flex items-center space-x-3">
                                <i className="las la-list text-lg"></i>
                                <span className="leading-4 text-sm text-neutral-500 dark:text-neutral-400">
                                  {temasDisponibles} Temas disponibles
                                </span>
                              </div>
                              <div className="flex items-center space-x-3">
                                <i className="las la-check text-lg"></i>
                                <span className="leading-4 text-sm text-neutral-500 dark:text-neutral-400">
                                  {item.attributes.asks?.data.length} preguntas
                                  en total
                                </span>
                              </div>
                              <div className="flex items-center space-x-3">
                                <SwordsIcon size={16} />
                                <span className="leading-4 text-sm text-neutral-500 dark:text-neutral-400">
                                  6 Retos
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Convocatorias
