import { useMutation } from '@tanstack/react-query'
import { ChallengeType } from 'store/exam.store'

import { axiosClient } from 'utils/axios-client'
import { ResultadosReto, RetoMutationResponse } from 'utils/axios-sdk'

type RetoMutationVariables = {
  creador: string
  participantes: string[]
  temario?: string
  oposicion?: string
  resultados: ResultadosReto[]
  asks: string[]
  slug: string
  type: ChallengeType
  typeCorrection: 'after' | 'instant'
  recordAlias?: Record<number, string>
}

export const retoMutation = (props: RetoMutationVariables) => {
  return axiosClient.post<{ data: RetoMutationResponse }>('/retos', {
    data: props
  })
}

const useRetoMutation = () => {
  return useMutation({ mutationFn: retoMutation })
}

export default useRetoMutation
