import Button from 'shared/Button/Button'
import { Card } from 'components/shared/card/card'
import { ClipboardIcon } from 'lucide-react'
import { useExamStore } from 'store/exam.store'
import { toast } from 'react-toastify'

const ShareChallenge = () => {
  const { set, challengeSlug } = useExamStore((state) => ({
    set: state.set,
    challengeSlug: state.challengeSlug
  }))

  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">
          Compartir reto
        </h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Antes de seguir adelante, te invitamos a compartir el siguiente código
          con las personas que deseas desafiar. Si tu desafío es para la
          comunidad, podrán acceder a él directamente desde la pestaña de 'Retos
          Disponibles'.
        </h3>
      </div>

      <Card className="p-3 flex items-center w-full">
        <pre className="w-full flex justify-center">
          <code className="mx-auto">{challengeSlug}</code>
        </pre>
        <Button
          className="flex gap-3 p-3"
          onClick={() => {
            if (!challengeSlug) {
              toast.error('No se ha generado un código para compartir')
              return
            }

            if (navigator?.clipboard) {
              navigator.clipboard
                .writeText(challengeSlug)
                .then(() => {
                  toast.success('Código copiado al portapapeles')
                })
                .catch(() => {
                  toast.error('No se ha podido copiar el código')
                })
            } else {
              console.log('No se puede copiar el texto')
              toast.error('No soportardo')
            }
          }}
        >
          <ClipboardIcon />
          <span className="hidden md:block">Copiar</span>
        </Button>
      </Card>

      <Button
        className="w-full mt-auto ml-auto md:w-max"
        onClick={() => set({ view: 'list-asks' })}
      >
        Comenzar
      </Button>
    </>
  )
}

export default ShareChallenge
