import { useStore } from 'store'
import ncNanoId from 'utils/ncNanoId'
import NavigationItem, { NavItemType } from './NavigationItem'

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: 'https://oposicionesbombero.com/',
    targetBlank: true,
    name: 'Plataforma'
  },
  {
    id: ncNanoId(),
    href: '/',
    name: 'Inicio'
  },
  {
    id: ncNanoId(),
    href: '/convocatorias',
    name: 'Convocatorias'
  },
  {
    id: ncNanoId(),
    href: '/temarios',
    name: 'Temarios'
  }
]

function Navigation() {
  const { user } = useStore()

  return (
    <ul className="nc-Navigation hidden md:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}

      <NavigationItem
        menuItem={{
          id: ncNanoId(),
          href: user?.id ? '/retos' : '/login?redirect=/retos',
          name: 'Retos'
        }}
      />
    </ul>
  )
}

export default Navigation
