import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Input from 'shared/Input/Input'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { useStore } from 'store'
import { postLogin } from 'api/auth'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export interface PageLoginProps {
  className?: string
}

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
  const navigate = useNavigate()
  const { login, user } = useStore()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect') || '/'

  const { mutate, isPending } = useMutation({
    mutationFn: postLogin
  })

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    if (!e.currentTarget.email.value || !e.currentTarget.password.value) {
      toast.error('Por favor, complete todos los campos.')
      return
    }

    mutate(
      {
        identifier: e.currentTarget.email.value,
        password: e.currentTarget.password.value
      },
      {
        onSuccess: (data) => {
          login(data.jwt, data.user)
        },
        onError: (error) => {
          const _error = error as AxiosError<{ error: { message: string } }>
          console.log('[ERROR_LOGIN]', error)

          const errorRecord: Record<string, string> = {
            'Invalid identifier or password': 'Correo o contraseña incorrectos'
          }

          if (errorRecord[_error.response?.data?.error.message ?? '']) {
            toast.error(errorRecord[_error.response?.data?.error.message ?? ''])
            return
          }

          toast.error('Error desconocido, intente nuevamente.')
        }
      }
    )
  }

  useEffect(() => {
    if (!user) return
    navigate(redirect)
  }, [user])

  return (
    <div
      data-nc-id="PageLogin"
      className=" w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col justify-center mx-auto py-8 md:py-10"
    >
      <Helmet>
        <title>Inicio de sesión</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Iniciar sesión
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={onSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Correo
              </span>
              <Input
                type="email"
                placeholder="Ingrese su correo"
                name="email"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Contraseña
                {/* <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link> */}
              </span>
              <Input
                type="password"
                name="password"
                placeholder="Ingrese su contraseña"
                className="mt-1"
              />
            </label>
            <ButtonPrimary type="submit" loading={isPending}>
              Ingresar
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Nuevo usuario? {` `}
            <Link to={'/signup?redirect=' + redirect} className="underline">
              Crear una cuenta
            </Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PageLogin
