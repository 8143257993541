import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from 'components/shared/tabs/tabs'
import React from 'react'
import { useParams } from 'react-router-dom'
import TabParticipants from './tab-participants'
import TabResults from './tab-results'

type Props = {}

const Reto = (props: Props) => {
  const { id } = useParams()

  return (
    <div className="w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl md:text-3xl font-semibold">Reto #{id}</h2>
      </div>

      <Tabs defaultValue="myResults">
        <TabsList className="w-full">
          <TabsTrigger value="myResults" className="w-full">
            Mis resultados
          </TabsTrigger>
          <TabsTrigger value="participants" className="w-full">
            Participantes
          </TabsTrigger>
        </TabsList>
        <TabsContent value="myResults">
          <TabResults />
        </TabsContent>
        <TabsContent value="participants">
          <TabParticipants />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default Reto
