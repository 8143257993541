import { Card } from 'components/shared/card/card'
import dayjs from 'dayjs'
import useRetosQuery from 'hooks/query/use-retos.query'
import { ChevronRightIcon, Loader2Icon } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useStore } from 'store'
import { useExamStore } from 'store/exam.store'

type Props = {}

const TabCommunity = (props: Props) => {
  const { user } = useStore()
  const navigate = useNavigate()

  const retosQuery = useRetosQuery({
    enabled: !!user?.id,
    variables: {
      sort: 'createdAt:desc',
      populate: 'creador,oposicion,temario,participantes,asks,asks.temas',
      filters: { type: { $eq: 'community' } }
    }
  })

  return (
    <>
      {retosQuery.isLoading && (
        <div className="w-full h- grid place-items-center pt-20">
          <Loader2Icon className="animate-spin" />
        </div>
      )}

      {!retosQuery.isLoading && (
        <>
          {retosQuery?.data?.data?.data?.map((reto) => {
            const isOposicion = !!reto?.attributes?.oposicion?.data?.id

            return (
              <Card
                key={reto.id}
                className="p-3 md:p-5 mt-3 space-y-1"
                onClick={() => {
                  if (!user?.id) {
                    toast.error(
                      'Debes iniciar sesión para participar en un reto'
                    )
                    return
                  }

                  if (
                    reto?.attributes?.participantes?.data?.some(
                      (p) => p.id === user?.id
                    )
                  ) {
                    navigate('/retos/' + reto.id)
                    return
                  }

                  if (reto?.attributes?.oposicion?.data?.id) {
                    useExamStore.getState().loadRetoData(reto)
                    navigate(
                      '/oposicion/' + reto?.attributes?.oposicion?.data?.id
                    )
                    return
                  }

                  if (reto?.attributes?.temario?.data?.id) {
                    useExamStore.getState().loadRetoData(reto)
                    navigate('/temarios/' + reto?.attributes?.temario?.data?.id)
                    return
                  }

                  toast.error('No se puede participar en este reto')
                }}
              >
                <p className="font-semibold">
                  {isOposicion
                    ? reto?.attributes?.oposicion?.data?.attributes?.nombre
                    : reto?.attributes?.temario?.data?.attributes?.title}
                </p>
                <p className="text-sm text-slate-600 dark:text-slate-400">
                  Tipo: {isOposicion ? 'Oposición' : 'Temario'}
                </p>
                <p className="text-sm text-slate-600 dark:text-slate-400">
                  Creador:{' '}
                  {reto?.attributes?.creador?.data?.attributes?.username}
                </p>
                <div className="flex items-center justify-between">
                  <p className="text-sm text-slate-600 dark:text-slate-400">
                    Fecha:{' '}
                    {dayjs(reto?.attributes?.createdAt).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </p>

                  <ChevronRightIcon className="w-5 h-5" />
                </div>
              </Card>
            )
          })}
        </>
      )}
    </>
  )
}

export { TabCommunity }
