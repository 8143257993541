import { useExamStore } from 'store/exam.store'
import { Card } from 'components/shared/card/card'
import { UserIcon, Users2Icon } from 'lucide-react'

type Props = {}

const SelectChallengeType = (props: Props) => {
  const { set } = useExamStore((state) => ({
    set: state.set
  }))

  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">Tipo de reto</h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Seleccione el tipo de reto que desea crear.
        </h3>
      </div>

      <Card
        className="p-3 md:p-5 mb-3"
        onClick={() => {
          set({
            view: 'generate-challenge',
            challengeType: 'personal'
          })
        }}
      >
        <div className="flex items-center gap-3 mb-1">
          <UserIcon />
          <h3 className="text-xl font-semibold mb-1">Reto personal</h3>
        </div>

        <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
          Este reto solo estará disponible para dos personas. Tendrá que
          compartir un código para que otro usuario pueda unirse.
        </p>
      </Card>

      <Card
        className="p-3 md:p-5 mb-3"
        onClick={() => {
          set({
            view: 'generate-challenge',
            challengeType: 'community'
          })
        }}
      >
        <div className="flex items-center gap-3 mb-1">
          <Users2Icon />
          <h3 className="text-xl font-semibold mb-1">Reto de comunidad</h3>
        </div>

        <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
          Este reto estará disponible para toda la comunidad. Cualquier otro
          usuario podrá unirse a su reto desde la lista de retos disponibles.
        </p>
      </Card>
    </>
  )
}

export default SelectChallengeType
