import { useStore } from 'store'
import { Echarts } from 'components/shared/echarts/echarts'
import useOposicionesResponsedQuery from 'hooks/query/use-oposiciones-responsed.query'
import { getPieChartAskOptions } from 'utils/get-pie-chart-ask-options'
import { useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Loader2Icon } from 'lucide-react'
import useUserQuery from 'hooks/query/use-user-query'

type Props = {}

const TabStatistics = (props: Props) => {
  const { user } = useStore()

  const userQuery = useUserQuery({
    userId: user?.id,
    enabled: !!user?.id,
    variables: { populate: 'blank_question.asks,failed_question.asks' }
  })

  const { data, isLoading } = useOposicionesResponsedQuery({
    enabled: !!user?.id,
    variables: {
      pagination: {
        pageSize: 1000
      },
      filters: {
        user: {
          id: { $eq: user?.id }
        },
        createdAt: {
          $gte: useMemo(
            () => dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
            []
          )
        }
      }
    }
  })

  const {
    max,
    min,
    totalFailed,
    totalSuccess,
    totalNoAnswer,
    totalTime,
    categories,
    datasuccess,
    datafailed,
    datanoAnswer
  } = useMemo(() => {
    if (!data?.data?.data) {
      return {
        correct: 0,
        totalTime: 0,
        categories: [],
        datafailed: [],
        datasuccess: [],
        datanoAnswer: [],
        totalFailed: 0,
        totalSuccess: 0,
        totalNoAnswer: 0,
        max: 0,
        min: 0
      }
    }

    let correct = 0
    let totalTime = 0
    let totalFailed = 0
    let totalSuccess = 0
    let totalNoAnswer = 0

    const datafailed: number[] = []
    const datasuccess: number[] = []
    const datanoAnswer: number[] = []
    const categories: Dayjs[] = new Array(7)
      .fill(0)
      .map((_, i) => dayjs().subtract(6 - i, 'days'))

    for (const date of categories) {
      let failed = 0
      let success = 0
      let noAnswer = 0

      data.data.data.forEach((item) => {
        const createdAt = dayjs(item.attributes.createdAt).format('DD-MM-YYYY')
        if (date.format('DD-MM-YYYY') === createdAt) {
          item.attributes.asksAndResponses.forEach((ask) => {
            if (ask.marked === null) {
              noAnswer++
              return
            }

            if (ask.marked === ask.correct) {
              success++
            } else {
              failed++
            }
          })
        }
      })

      totalFailed += failed
      totalSuccess += success
      totalNoAnswer += noAnswer

      datafailed.push(failed)
      datasuccess.push(success)
      datanoAnswer.push(noAnswer)
    }

    for (const op of data.data.data) {
      if (!op?.id) continue

      const time = dayjs(op.attributes.time, 'HH:mm:ss.SSS')
      const duration = dayjs.duration({
        hours: time.hour(),
        minutes: time.minute(),
        seconds: time.second()
      })

      totalTime += duration.asMilliseconds()

      for (const answer of op.attributes.asksAndResponses) {
        if (answer.marked === answer.correct) {
          correct++
        }
      }
    }

    return {
      correct,
      totalTime,
      categories,
      datafailed,
      datasuccess,
      datanoAnswer,
      totalFailed,
      totalSuccess,
      totalNoAnswer,
      max: Math.max(...datafailed, ...datasuccess, ...datanoAnswer),
      min: Math.min(...datafailed, ...datasuccess, ...datanoAnswer)
    }
  }, [data?.data.data])

  if (isLoading) {
    return (
      <div className="w-full grid place-items-center pt-20">
        <Loader2Icon className="animate-spin" />
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center justify-around my-6">
        <div>
          <p className="text-3xl text-center font-bold">
            {data?.data?.data?.length}
          </p>
          <p className="text-center dark:text-slate-500">Test realizados</p>
        </div>

        <div>
          <p className="text-3xl text-center font-bold">
            {dayjs.duration(totalTime).format('HH:mm:ss')}
          </p>
          <p className="text-center dark:text-slate-500">Tiempo total</p>
        </div>
      </div>

      <div className="grid md:grid-cols-2 md:items-center">
        <Echarts
          className="w-full aspect-1"
          option={getPieChartAskOptions({
            correct: totalSuccess,
            blank: totalNoAnswer,
            fail: totalFailed
          })}
        />

        <Echarts
          className="w-full aspect-1"
          option={{
            yAxis: {
              type: 'value',
              min: min < 2 ? min : min - 2,
              max: max + 2
            },
            backgroundColor: 'transparent',
            xAxis: {
              type: 'category',
              data: categories.map((date) => date.format('DD MMM'))
            },
            legend: {
              show: true,
              left: 'center'
            },
            series: [
              {
                data: datasuccess,
                color: '#22c55e',
                lineStyle: { color: '#22c55e' },

                type: 'line',
                smooth: true,
                name: 'Acertadas'
              },
              {
                data: datafailed,
                color: '#ef4444',
                lineStyle: { color: '#ef4444' },

                type: 'line',
                smooth: true,
                name: 'Fallidas'
              },
              {
                data: datanoAnswer,
                color: '#eab308',
                lineStyle: { color: '#eab308' },

                type: 'line',
                smooth: true,
                name: 'Sin Respuesta'
              }
            ]
          }}
        />
      </div>
    </>
  )
}

export default TabStatistics
