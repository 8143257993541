import { useExamStore } from 'store/exam.store'
import { Card } from 'components/shared/card/card'
import useTemaQuery from 'hooks/query/use-tema.query'
import { Skeleton } from 'components/shared/skeleton/skeleton'
import Button from 'shared/Button/Button'
import useRetoMutation from 'hooks/mutation/use-reto.mutation'
import { useStore } from 'store'
import slugify from 'slugify'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {}

const ResumeTopicCard = ({ topicId }: { topicId?: number }) => {
  const { data, isLoading } = useTemaQuery({
    temaId: topicId,
    enabled: Boolean(topicId)
  })

  const topic = data?.data?.data?.attributes ?? null

  return (
    <Card key={topicId} className="p-3 md:p-5 mb-3">
      {isLoading && (
        <div className="w-full h-full">
          <div className="flex items-center gap-3 mb-2">
            <Skeleton className="flex-1 h-5" />
          </div>
          <Skeleton className="w-full h-5" />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="flex items-center gap-3">
            <h3 className="text-xl font-semibold">{topic?.title}</h3>
          </div>

          <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
            {topic?.description}
          </p>
        </>
      )}
    </Card>
  )
}

const GenerateChallenge = (props: Props) => {
  const { id } = useParams()
  const { user } = useStore()
  const store = useExamStore()

  const { mutate, isPending } = useRetoMutation()

  const handleSubmit = async () => {
    if (!user?.id) return

    const now = new Date().getTime()
    const toSlug = `retos-${user.username}-${now}`.toLowerCase()
    const slug = slugify(toSlug)

    mutate(
      {
        slug,
        temario: id!,
        resultados: [],
        participantes: [],
        creador: String(user.id),
        type: store.challengeType!,
        asks: store.sliceAsks.map(String),
        typeCorrection: store.correction!,
        recordAlias: store.recordAlias
      },
      {
        onSuccess: (reto) => {
          if (!reto?.data?.data?.id) {
            console.error('No se ha podido generar el reto')
            toast.error('No se ha podido generar el reto')
            return
          }

          toast.success('Reto generado con éxito')
          store.set({
            view: 'share-challenge',
            challengeId: String(reto.data.data.id),
            challengeSlug: reto.data.data.attributes.slug
          })
        },
        onError: (error) => {
          console.error(error)
        }
      }
    )
  }

  return (
    <>
      <h2 className="text-xl md:text-3xl font-semibold mb-6">Generar reto</h2>

      <ul className="space-y-4 md:space-y-6">
        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">Temas</h3>
          {store.selectedTopics.map((topicId) => (
            <ResumeTopicCard key={topicId} topicId={topicId} />
          ))}
        </li>

        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">
            Número de preguntas
          </h3>
          <p className="text-neutral-500 dark:text-neutral-400">
            {store.numberOfAsks} preguntas
          </p>
        </li>

        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">
            Tipo de corrección
          </h3>
          <p className="text-neutral-500 dark:text-neutral-400">
            {store.correction === 'after'
              ? 'Después de responder todo'
              : 'Cada vez que responda'}
          </p>
        </li>

        <li>
          <h3 className="text-lg md:text-2xl font-semibold mb-1">
            Tipo de reto
          </h3>
          <p className="text-neutral-500 dark:text-neutral-400">
            {store.challengeType === 'personal'
              ? 'Reto personal'
              : 'Reto de comunidad'}
          </p>
        </li>
      </ul>

      <Button
        disabled={isPending}
        onClick={handleSubmit}
        className="bg-black bg-opacity-10 dark:bg-white dark:bg-opacity-10 rounded-md mt-auto w-full sm:w-max ml-auto"
      >
        {isPending ? 'Generando reto...' : 'Generar reto'}
      </Button>
    </>
  )
}

export default GenerateChallenge
