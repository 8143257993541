import { useExamStore } from 'store/exam.store'
import useTemaQuery from 'hooks/query/use-tema.query'
import { Skeleton } from 'components/shared/skeleton/skeleton'
import { Card, CardCheckbox } from 'components/shared/card/card'
import Button from 'shared/Button/Button'
import useToggle from 'hooks/use-toggle'
import SelectNumberAsks from 'components/shared/select-number-asks/select-number-asks'

type Props = {}

const TopicCard = ({ topicId }: { topicId?: number }) => {
  const { set, recordAlias, selectedTopics } = useExamStore((state) => ({
    set: state.set,
    recordAlias: state.recordAlias,
    selectedTopics: state.selectedTopics
  }))

  const { data, isLoading } = useTemaQuery({
    temaId: topicId,
    enabled: Boolean(topicId)
  })

  const topic = data?.data?.data?.attributes ?? null

  const handleToggle = () => {
    if (!topicId) return

    if (selectedTopics.includes(topicId)) {
      set({
        selectedTopics: selectedTopics.filter((id) => id !== topicId)
      })
    } else {
      set({ selectedTopics: [...selectedTopics, topicId] })
    }
  }

  return (
    <Card key={topicId} className="p-3 md:p-5 mb-3" onClick={handleToggle}>
      {isLoading && (
        <div className="w-full h-full">
          <div className="flex items-center gap-3 mb-2">
            <CardCheckbox checked={false} onChange={() => {}} />
            <Skeleton className="flex-1 h-5" />
          </div>
          <Skeleton className="w-full h-5" />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="flex items-center gap-3 mb-2">
            <CardCheckbox
              onChange={handleToggle}
              checked={topicId ? selectedTopics.includes(topicId) : false}
            />
            <h3 className="text-xl font-semibold mb-1">
              {recordAlias[topicId!] ?? topic?.title}
            </h3>
          </div>

          <p className="text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
            {topic?.description}
          </p>
        </>
      )}
    </Card>
  )
}

const SelectTopic = (props: Props) => {
  const modalToggle = useToggle()
  const { set, type, topics } = useExamStore((state) => ({
    set: state.set,
    type: state.type,
    topics: Object.keys(state.topics).map(Number)
  }))

  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">
          Elige los temas
        </h2>
        <h3 className="text-neutral-500 dark:text-neutral-400">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, fuga?
          Itaque autem pariatur deleniti dolorem necessitatibus dignissimos
          quam.
        </h3>
      </div>

      {topics.length === 0 && type === 'failed' && (
        <div className="w-full h-full flex-1 grid place-items-center">
          <div className="text-center space-y-3">
            <p>No posees preguntas falladas</p>
            <button onClick={() => set({ type: null })} className="underline">
              Volver
            </button>
          </div>
        </div>
      )}

      {topics.length === 0 && type === 'blank' && (
        <div className="w-full h-full flex-1 grid place-items-center">
          <div className="text-center space-y-3">
            <p>No posees preguntas en blanco</p>
            <button onClick={() => set({ type: null })} className="underline">
              Volver
            </button>
          </div>
        </div>
      )}

      {topics.map((topicId) => (
        <TopicCard key={topicId} topicId={topicId} />
      ))}

      <Button
        fontSize="text-base sm:text-lg font-medium"
        className="mt-auto w-full sm:w-max ml-auto dark:bg-white dark:bg-opacity-10 rounded-md bg-black bg-opacity-10"
        onClick={() => modalToggle.open()}
      >
        Continuar
      </Button>

      <SelectNumberAsks isOpen={modalToggle.isOpen} close={modalToggle.close} />
    </>
  )
}

export default SelectTopic
