import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { AskQueryResponse } from 'utils/axios-sdk'

type Props = {
  askId?: number
  enabled?: boolean
  variables?: object
}

export const askQuery = (askId?: number, params?: object) => {
  return axiosClient.get<{ data: AskQueryResponse }>('/asks/' + askId, {
    params
  })
}

const useAskQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['ask', props.askId, props.variables],
    queryFn: async ({ queryKey }) => {
      return askQuery(queryKey[1] as number, queryKey[2] as object)
    }
  })
}

export default useAskQuery
