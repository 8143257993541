import { Dialog } from '@headlessui/react'
import { useMutation } from '@tanstack/react-query'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from 'components/shared/tabs/tabs'
import { motion } from 'framer-motion'
import { retosQuery } from 'hooks/query/use-retos.query'
import useToggle from 'hooks/use-toggle'
import { PlusIcon } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'shared/Button/Button'
import Input from 'shared/Input/Input'
import { useStore } from 'store'
import { useExamStore } from 'store/exam.store'
import { TabCommunity } from './tab-community'
import { TabPersonal } from './tab-personal'

type Props = {}

const Retos = (props: Props) => {
  const { user } = useStore()
  const navigate = useNavigate()
  const toggleModal = useToggle()
  const [code, setCode] = React.useState('')

  const { mutate, isPending } = useMutation({
    mutationFn: retosQuery
  })

  const handleSubmit = async () => {
    if (code?.trim().length === 0) {
      return toast.error('El código del reto es requerido')
    }

    mutate(
      {
        filters: { slug: { $eq: code } },
        populate: 'creador,oposicion,temario,participantes,asks,asks.temas'
      },
      {
        onSuccess: (retos) => {
          if (retos?.data?.data?.length === 0) {
            return toast.error('El código del reto no existe')
          }

          const reto = retos.data.data[0]
          const hasMyParticipation = reto.attributes.participantes?.data?.some(
            (p) => p.id === user?.id
          )

          if (hasMyParticipation) {
            return toast.info('Ya estás participando en este reto')
          }

          if (reto?.attributes?.oposicion?.data?.id) {
            useExamStore.getState().loadRetoData(reto)
            navigate('/oposicion/' + reto.attributes.oposicion?.data.id)
            return
          }

          if (reto?.attributes?.temario?.data?.id) {
            useExamStore.getState().loadRetoData(reto)
            navigate('/temarios/' + reto?.attributes?.temario?.data?.id)
            return
          }

          toast.error('No se ha podido iniciar el reto')
        }
      }
    )
  }

  return (
    <>
      <div className="w-[90vw] max-w-5xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl md:text-3xl font-semibold">Retos</h2>

          <Button onClick={toggleModal.open}>
            <PlusIcon className="w-4 h-4 mr-2" />
            Unirse a un reto
          </Button>
        </div>

        <Tabs defaultValue="personal">
          <TabsList className="w-full">
            <TabsTrigger value="personal" className="w-full">
              Personal
            </TabsTrigger>
            <TabsTrigger value="community" className="w-full">
              Comunidad
            </TabsTrigger>
          </TabsList>
          <TabsContent value="personal">
            <TabPersonal />
          </TabsContent>
          <TabsContent value="community">
            <TabCommunity />
          </TabsContent>
        </Tabs>
      </div>

      <Dialog
        open={toggleModal.isOpen}
        onClose={() => toggleModal.close()}
        className="fixed inset-0 z-50 flex items-center justify-center "
      >
        <Dialog.Overlay
          as={motion.div}
          key="backdrop"
          className="fixed inset-0 z-30 bg-black bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={toggleModal.isOpen ? { opacity: 1 } : { opacity: 0 }}
        />

        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={
            toggleModal.isOpen
              ? { scale: 1, opacity: 1 }
              : { scale: 0.5, opacity: 0 }
          }
          className="p-3 md:p-5 bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 rounded relative z-50 flex flex-col aspect-[3/2] w-full max-w-sm"
        >
          <h3 className="text-lg md:text-xl font-semibold mb-1">
            Ingresar a un reto
          </h3>
          <p className="text-neutral-500 dark:text-neutral-400 text-sm mb-3">
            ¿Estás listo para unirte a un reto?, ingresa el código del reto para
            comenzar.
          </p>

          <Input
            placeholder="Ingresa el código del reto"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <Button
            disabled={isPending}
            onClick={handleSubmit}
            className="w-full mt-auto"
          >
            {isPending ? 'Cargando...' : 'Unirse'}
          </Button>
        </motion.div>
      </Dialog>
    </>
  )
}

export default Retos
