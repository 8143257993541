import axios from "axios";
import { BASE_API_URL } from "api";
import {
  TConvocatoriasResponse,
  TOposicionesConvocatoriaResponse,
} from "./types";

export const getConvocatorias = async () => {
  const response = await axios.get(`${BASE_API_URL}/convocatorias?populate=*`);
  return response.data as TConvocatoriasResponse;
};

export const getOposicionesFromConvocatoria = async (id: number) => {
  // https://hqvx92pl-1338.use.devtunnels.ms/api/convocatorias/2?populate=oposiciones.asks
  const response = await axios.get(
    `${BASE_API_URL}/convocatorias/${id}?populate=oposiciones.asks,oposiciones.asks.temas,oposiciones.convocatorias`
  );
  return response.data as TOposicionesConvocatoriaResponse;
};

export * from "./types";
