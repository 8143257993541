import React, { useEffect } from 'react'
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom'
import { Page } from './types'
import ScrollToTop from './ScrollToTop'
import Footer from 'shared/Footer/Footer'
import PageSignUp from 'containers/PageSignUp/PageSignUp'
import PageLogin from 'containers/PageLogin/PageLogin'
import SiteHeader from 'containers/SiteHeader'
import Convocatorias from 'containers/Convocatorias'
import Oposicion from 'containers/Oposicion'
import Retos from 'containers/Retos'
import Actividad from 'containers/Actividad'
import Reto from 'containers/Reto'
import DetalleActividad from 'containers/DetalleActividad'
import { useStore } from 'store'
import { axiosClient } from 'utils/axios-client'
import { User } from 'utils/axios-sdk'
import TemariosPage from 'containers/TemariosPage'
import { Loader2 } from 'lucide-react'
import MisTest from 'containers/MisTest'
import NuevoTest from 'containers/NuevoTest'
import DetalleTest from 'containers/DetalleTest'
import PageHome from 'containers/PageHome/PageHome'
import Temario from 'containers/Temario'

export const pages: Page[] = [
  { path: '/retos', exact: true, component: Retos },
  { path: '/retos/:id', exact: true, component: Reto },
  { path: '/actividad', exact: true, component: Actividad },
  { path: '/oposicion/:id', exact: true, component: Oposicion },
  { path: '/actividad/:id', exact: true, component: DetalleActividad },
  { path: '/mis-test', exact: true, component: MisTest },
  { path: '/mis-test/:id', exact: true, component: DetalleTest },
  { path: '/mis-test/nuevo', exact: true, component: NuevoTest },
  { path: '/temarios/:id', exact: true, component: Temario }

  //
]

const MyRoutes = () => {
  const [loading, setLoading] = React.useState(true)
  const { user, login, logout } = useStore()

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      axiosClient
        .get<User>('/users/me')
        .then((res) => {
          console.log('user', res)

          if (!res?.data?.id) {
            logout()
            return
          }

          login(token, res.data)
        })
        .catch((err) => {
          console.log('err', err)
          logout()
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      logout()
      setLoading(false)
    }
  }, [])

  if (loading) {
    return (
      <div className="w-full h-screen grid place-items-center bg-white dark:bg-neutral-900">
        <Loader2 size={64} className="animate-spin" />
      </div>
    )
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        <Route path="/" element={<PageHome />} />
        <Route path="/login" element={<PageLogin />} />
        <Route path="/signup" element={<PageSignUp />} />
        <Route path="/temarios" element={<TemariosPage />} />
        <Route path="/convocatorias" element={<Convocatorias />} />

        {user &&
          pages.map(({ component, path }) => {
            const Component = component
            return <Route key={path} element={<Component />} path={path} />
          })}

        {/* <Route path="*" element={<Page404 />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  )
}

export default MyRoutes
