import Logo from 'shared/Logo/Logo'
import SocialsList1 from 'shared/SocialsList1/SocialsList1'
import { CustomLink } from 'data/types'
import React from 'react'

export interface WidgetFooterMenu {
  id: string
  title: string
  menus: CustomLink[]
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: '5',
    title: 'Getting started',
    menus: [
      { href: '#', label: 'Installation' },
      { href: '#', label: 'Release Notes' },
      { href: '#', label: 'Upgrade Guide' },
      { href: '#', label: 'Browser Support' },
      { href: '#', label: 'Editor Support' }
    ]
  },
  {
    id: '1',
    title: 'Explore',
    menus: [
      { href: '#', label: 'Design features' },
      { href: '#', label: 'Prototyping' },
      { href: '#', label: 'Design systems' },
      { href: '#', label: 'Pricing' },
      { href: '#', label: 'Security' }
    ]
  },
  {
    id: '2',
    title: 'Resources',
    menus: [
      { href: '#', label: 'Best practices' },
      { href: '#', label: 'Support' },
      { href: '#', label: 'Developers' },
      { href: '#', label: 'Learn design' },
      { href: '#', label: 'Releases' }
    ]
  },
  {
    id: '4',
    title: 'Community',
    menus: [
      { href: '#', label: 'Discussion Forums' },
      { href: '#', label: 'Code of Conduct' },
      { href: '#', label: 'Community Resources' },
      { href: '#', label: 'Contributing' },
      { href: '#', label: 'Concurrent Mode' }
    ]
  }
]

const Footer: React.FC = () => {
  return (
    <div className="w-full  p-8 text-slate-500 dark:text-slate-400 text-sm border-t dark:border-t-slate-800">
      <div className="max-w-4xl mx-auto w-full md:w-[90vw] flex flex-col md:flex-row md:gap-2">
        <p>&copy; 2024 Oposiciones Bombero.</p>
        <p>
          Diseñado y Desarrollado por{' '}
          <a className="font-bold" href="https://desarrollosmoyan.com/">
            Desarrollos Moyan
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
