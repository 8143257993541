import { useQuery } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import type { TemaQueryResponse } from 'utils/axios-sdk'

type Props = {
  enabled?: boolean
  temaId?: number
  variables?: object
}

export const temaQuery = (temaId?: number, params?: object) => {
  return axiosClient.get<{ data: TemaQueryResponse }>('/temas/' + temaId, {
    params
  })
}

const useTemaQuery = (props: Props = {}) => {
  return useQuery({
    enabled: props.enabled,
    queryKey: ['tema', props.temaId, props.variables],
    queryFn: async ({ queryKey }) => {
      return temaQuery(queryKey[1] as number, queryKey[2] as object)
    }
  })
}

export default useTemaQuery
