export interface TOposicionesConvocatoriaResponse {
  data: DAT;
  meta: Meta;
}

interface Convocatorias {
  data: DAT[];
}

interface PurpleAttributes {
  nombre: string;
  createdAt: Date;
  updatedAt: Date;
  convocatorias: Convocatorias;
  asks: Asks;
}

interface OposicionesDatum {
  id: number;
  attributes: PurpleAttributes;
}

interface Oposiciones {
  data: OposicionesDatum[];
}

interface DataAttributes {
  nombre: string;
  createdAt: Date;
  updatedAt: Date;
  descripcion: string;
  geo: null;
  provincia: string;
  oposiciones: Oposiciones;
}

interface DAT {
  id: number;
  attributes: DataAttributes;
}

interface Asks {
  data: AsksDatum[];
}

interface AsksDatum {
  id: number;
  attributes: FluffyAttributes;
}

interface FluffyAttributes {
  description: string;
  responseA: string;
  responseB: string;
  responseC: string;
  responseD: string;
  createdAt: Date;
  updatedAt: Date;
  A: boolean;
  B: boolean;
  C: boolean;
  D: boolean;
  explicacionRespuesta: null;
  temas: {
    data: {
      id: number;
      attributes: {
        title: string;
        description: string;
        createdAt: string;
        updatedAt: string;
      };
    }[];
  };
}

interface Meta {}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
class Convert {
  public static toWelcome(json: string): TOposicionesConvocatoriaResponse {
    return cast(JSON.parse(json), r("TOposicionesConvocatoriaResponse"));
  }

  public static welcomeToJson(value: TOposicionesConvocatoriaResponse): string {
    return JSON.stringify(
      uncast(value, r("TOposicionesConvocatoriaResponse")),
      null,
      2
    );
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ""): never {
  const prettyTyp = prettyTypeName(typ);
  const parentText = parent ? ` on ${parent}` : "";
  const keyText = key ? ` for key "${key}"` : "";
  throw Error(
    `Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(
      val
    )}`
  );
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    } else {
      return `one of [${typ
        .map((a) => {
          return prettyTypeName(a);
        })
        .join(", ")}]`;
    }
  } else if (typeof typ === "object" && typ.literal !== undefined) {
    return typ.literal;
  } else {
    return typeof typ;
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(
  val: any,
  typ: any,
  getProps: any,
  key: any = "",
  parent: any = ""
): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(
      cases.map((a) => {
        return l(a);
      }),
      val,
      key,
      parent
    );
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue(l("Date"), val, key, parent);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue(l(ref || "object"), val, key, parent);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val, key, parent);
  }
  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any = undefined;
  while (typeof typ === "object" && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val, key, parent);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  TOposicionesConvocatoriaResponse: o(
    [
      { json: "data", js: "data", typ: r("DAT") },
      { json: "meta", js: "meta", typ: r("Meta") },
    ],
    false
  ),
  Convocatorias: o(
    [{ json: "data", js: "data", typ: u(undefined, a(r("DAT"))) }],
    false
  ),
  PurpleAttributes: o(
    [
      { json: "nombre", js: "nombre", typ: "" },
      { json: "createdAt", js: "createdAt", typ: Date },
      { json: "updatedAt", js: "updatedAt", typ: Date },
      { json: "convocatorias", js: "convocatorias", typ: r("Convocatorias") },
      { json: "asks", js: "asks", typ: r("Asks") },
    ],
    false
  ),
  OposicionesDatum: o(
    [
      { json: "id", js: "id", typ: u(undefined, 0) },
      {
        json: "attributes",
        js: "attributes",
        typ: u(undefined, r("PurpleAttributes")),
      },
    ],
    false
  ),
  Oposiciones: o(
    [{ json: "data", js: "data", typ: a(r("OposicionesDatum")) }],
    false
  ),
  DataAttributes: o(
    [
      { json: "nombre", js: "nombre", typ: "" },
      { json: "createdAt", js: "createdAt", typ: Date },
      { json: "updatedAt", js: "updatedAt", typ: Date },
      { json: "descripcion", js: "descripcion", typ: "" },
      { json: "geo", js: "geo", typ: null },
      { json: "provincia", js: "provincia", typ: "" },
      {
        json: "oposiciones",
        js: "oposiciones",
        typ: u(undefined, r("Oposiciones")),
      },
    ],
    false
  ),
  DAT: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "attributes", js: "attributes", typ: r("DataAttributes") },
    ],
    false
  ),
  Asks: o(
    [{ json: "data", js: "data", typ: u(undefined, a(r("AsksDatum"))) }],
    false
  ),
  AsksDatum: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "attributes", js: "attributes", typ: r("FluffyAttributes") },
    ],
    false
  ),
  FluffyAttributes: o(
    [
      { json: "description", js: "description", typ: "" },
      { json: "responseA", js: "responseA", typ: "" },
      { json: "responseB", js: "responseB", typ: "" },
      { json: "responseC", js: "responseC", typ: "" },
      { json: "responseD", js: "responseD", typ: "" },
      { json: "createdAt", js: "createdAt", typ: Date },
      { json: "updatedAt", js: "updatedAt", typ: Date },
      { json: "A", js: "A", typ: true },
      { json: "B", js: "B", typ: true },
      { json: "C", js: "C", typ: true },
      { json: "D", js: "D", typ: true },
      { json: "explicacionRespuesta", js: "explicacionRespuesta", typ: null },
    ],
    false
  ),
  Meta: o([], false),
};
