import type { EChartsOption } from 'echarts'

export const getPieChartAskOptions = ({
  blank,
  correct,
  fail
}: {
  blank: number
  correct: number
  fail: number
}): EChartsOption => ({
  legend: {
    left: 'center',
    formatter(name) {
      if (name === 'Falladas') return `${name}: ${fail}`
      if (name === 'Acertadas') return `${name}: ${correct}`
      if (name === 'Sin contestar') return `${name}: ${blank}`
      return `${name}: ${blank}`
    }
  },
  tooltip: { trigger: 'item' },
  backgroundColor: 'transparent',
  media: [
    {
      query: {
        minWidth: 575
      },
      option: {
        series: [
          {
            emphasis: {
              label: {
                fontSize: '2rem'
              }
            }
          }
        ]
      }
    }
  ],
  series: [
    {
      name: 'Resultados',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '1rem',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },

      data: [
        {
          value: correct,
          name: 'Acertadas',
          itemStyle: { color: '#22c55e' }
        },
        {
          value: fail,
          name: 'Falladas',
          itemStyle: { color: '#ef4444' }
        },
        {
          value: blank,
          name: 'Sin contestar',
          itemStyle: { color: '#eab308' }
        }
      ]
    }
  ]
})
