import { Dialog } from '@headlessui/react'
import { motion } from 'framer-motion'
import React from 'react'
import Button from 'shared/Button/Button'
import { cn } from 'utils/cn'

type Props = {
  isOpen: boolean
  title: string
  description: string
  cancelLabel?: string
  acceptLabel?: string
  overlayClassName?: string

  onClose?: () => void
  onAccept?: () => void
  onCancel?: () => void
}

const Alert = (props: Props) => {
  const initialFocusRef = React.useRef<HTMLButtonElement | null>(null)

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onClose?.()}
      className="fixed inset-0 z-50 flex items-center justify-center "
    >
      <Dialog.Overlay
        as={motion.div}
        key="backdrop"
        className={cn(
          'fixed inset-0 z-30 bg-black bg-opacity-50',
          props.overlayClassName
        )}
        initial={{ opacity: 0 }}
        animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
      />

      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={
          props.isOpen ? { scale: 1, opacity: 1 } : { scale: 0.5, opacity: 0 }
        }
        className="p-5 bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 rounded relative z-50 flex flex-col w-full max-w-sm"
      >
        <h3 className="text-2xl font-semibold mb-2">{props.title}</h3>
        <p className="text-neutral-500 dark:text-neutral-400 mb-6">
          {props.description}
        </p>

        <div className="flex justify-end gap-3 mt-auto">
          {props.onCancel && (
            <Button
              className="border border-white border-opacity-20 rounded-md"
              onClick={() => {
                props.onCancel?.()
              }}
            >
              {props.cancelLabel || 'Cancelar'}
            </Button>
          )}

          {props.onAccept && (
            <Button
              autoFocus
              className="bg-white bg-opacity-10 rounded-md"
              onClick={() => {
                props.onAccept?.()
              }}
            >
              {props.acceptLabel || 'Aceptar'}
            </Button>
          )}
        </div>
      </motion.div>
    </Dialog>
  )
}

export default Alert
