import { Loader2Icon } from 'lucide-react'
import { useParams } from 'react-router-dom'

import { Card } from 'components/shared/card/card'
import useRetoQuery from 'hooks/query/use-reto.query'
import dayjs from 'dayjs'

type Props = {}

const TabParticipants = (props: Props) => {
  const { id } = useParams()

  const retoQuery = useRetoQuery({
    enabled: !!id,
    retoId: Number(id)
  })

  const reto = retoQuery.data?.data.data.attributes.resultados.sort(
    (a, b) => b.average - a.average
  )

  return (
    <>
      {retoQuery.isLoading && (
        <div className="w-full h- grid place-items-center pt-20">
          <Loader2Icon className="animate-spin" />
        </div>
      )}

      {!retoQuery.isLoading && (
        <>
          {reto?.map((p, idx) => (
            <Card
              key={p.opositionResponse}
              className="p-3 md:p-5 mt-3 space-y-1"
            >
              <div className="flex items-center gap-3">
                <div className="w-9 h-9 grid place-items-center border rounded-full dark:border-slate-600">
                  <span>{idx + 1} </span>
                </div>
                <p className="font-semibold flex-1">{p.username}</p>
              </div>

              <div className="flex items-center justify-between">
                <p>
                  Puntuación:{' '}
                  <span className="font-bold">{p.average.toFixed(1)} / 10</span>
                </p>

                <p>
                  Tiempo:{' '}
                  <span className="font-bold">
                    {dayjs(p.time, 'HH:mm:ss.SSS').format('HH:mm:ss')}
                  </span>
                </p>
              </div>
            </Card>
          ))}
        </>
      )}
    </>
  )
}

export default TabParticipants
