import { useMutation } from '@tanstack/react-query'

import { axiosClient } from 'utils/axios-client'
import { ExamType, SelectedAnswerType } from 'store/exam.store'
import { OposicionesResponsedMutationResponse } from 'utils/axios-sdk'

export type OposicionesResponsedMutationVariables = {
  asks: string[]
  average: number
  user: string
  asksAndResponses: SelectedAnswerType[]
  temario?: string
  oposicion?: string
  time: string
  typeTest: ExamType
}

export const oposicionesResponsedMutation = (
  props: OposicionesResponsedMutationVariables
) => {
  return axiosClient.post<{ data: OposicionesResponsedMutationResponse }>(
    '/oposiciones-responseds',
    {
      data: props
    }
  )
}

const useOposicionesResponsedMutation = () => {
  return useMutation({ mutationFn: oposicionesResponsedMutation })
}

export default useOposicionesResponsedMutation
