import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { buttonVariants } from './button'
import { cn } from 'utils/cn'

type Props = {
  page: number
  pageCount: number
  className?: string
  onPageChange: (page: number) => void
}

const buttonProps = buttonVariants({ size: 'icon', variant: 'ghost' })
const activeButtonProps = buttonVariants({ size: 'icon', variant: 'outline' })

const CustomPagination = (props: Props) => {
  return (
    <ReactPaginate
      forcePage={props.page - 1}
      onPageChange={(select) => {
        props.onPageChange(select.selected + 1)
      }}
      pageCount={props.pageCount}
      pageRangeDisplayed={3}
      renderOnZeroPageCount={null}
      className={cn('flex flex-row', props.className)}
      pageLinkClassName={buttonProps}
      nextLinkClassName={buttonProps}
      breakLinkClassName={buttonProps}
      previousLinkClassName={buttonProps}
      activeLinkClassName={activeButtonProps}
      nextLabel={<ChevronRight className="h-4 w-4" />}
      previousLabel={<ChevronLeft className="h-4 w-4" />}
      breakLabel={<MoreHorizontal className="h-4 w-4" />}
    />
  )
}

export default CustomPagination
