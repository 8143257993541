import React from 'react'
import ReactDOM from 'react-dom/client'
//
import 'rc-slider/assets/index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-spring-bottom-sheet/dist/style.css'

// STYLE
import './styles/index.scss'
import './index.css'
import './fonts/line-awesome-1.3.0/css/line-awesome.css'

//
import App from './App'
import reportWebVitals from './reportWebVitals'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/es'

dayjs.locale('es')
dayjs.extend(duration)
dayjs.extend(customParseFormat)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
