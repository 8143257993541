import { useMutation } from '@tanstack/react-query'
import { axiosClient } from 'utils/axios-client'
import { BlankQuestionMutationResponse } from 'utils/axios-sdk'

type UpdateFailedQuestionProps = {
  failedQuestionId: string
  asks?: string[]
  users_permissions_user?: string
}

export const updateFailedQuestion = ({
  failedQuestionId,
  ...data
}: UpdateFailedQuestionProps) => {
  return axiosClient.put<{ data: BlankQuestionMutationResponse }>(
    '/failed-questions/' + failedQuestionId,
    { data }
  )
}

const useUpdateFailedQuestionMutation = () => {
  return useMutation({ mutationFn: updateFailedQuestion })
}

export default useUpdateFailedQuestionMutation
