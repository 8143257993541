import { Trash2Icon } from 'lucide-react'
import { useParams } from 'react-router-dom'

import Button from 'shared/Button/Button'
import { Card, CardBody } from 'components/shared/card/card'
import { Accordion } from 'components/shared/accordion/accordion'
import { SimpleCardAsk } from 'components/shared/card-asks/simple-card-ask'
import {
  Tabs,
  TabsList,
  TabsContent,
  TabsTrigger
} from 'components/shared/tabs/tabs'
import { MIS_TEST } from 'data/mis-test'

type Props = {}

const DetalleTest = (props: Props) => {
  const { id } = useParams()
  const test = MIS_TEST.find((t) => t.id === Number(id)!) ?? null

  if (!test) return null

  return (
    <div className="w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl md:text-3xl font-semibold">{test.title}</h2>

        <div className="flex items-center gap-3">
          <label className="flex items-center gap-1">
            <input type="checkbox" id="isPublic" />
            <span className="ml-2">¿Es público?</span>
          </label>

          <Button>Realizar test</Button>
        </div>
      </div>

      <Tabs defaultValue="topics">
        <TabsList className="w-full">
          <TabsTrigger value="topics" className="w-full">
            Temas
          </TabsTrigger>
          <TabsTrigger value="asks" className="w-full">
            Preguntas
          </TabsTrigger>
        </TabsList>
        <TabsContent value="topics" className="space-y-3">
          {test.temas?.data.map((tema) => (
            <Card key={tema.id}>
              <CardBody className="p-3 flex items-center justify-between">
                <p className="text-lg font-semibold">{tema.attributes.title}</p>

                <Button>
                  <Trash2Icon className="w-4 h-4" />
                </Button>
              </CardBody>
            </Card>
          ))}
        </TabsContent>
        <TabsContent value="asks" className="space-y-3">
          <Accordion type="single" collapsible>
            {test.customAsks.map((ask, idx) => (
              <SimpleCardAsk
                key={ask.id}
                idx={idx + 1}
                ask={{ id: ask.id, attributes: ask }}
              />
            ))}

            {test.temas?.data.map((tema) => {
              return tema.attributes.TemaRelation?.data.map((ask, idx) => (
                <SimpleCardAsk ask={ask} key={ask.id} idx={idx + 1} />
              ))
            })}
          </Accordion>
        </TabsContent>
      </Tabs>

      <Button className="mt-6 w-full md:w-max ml-auto">Guardar</Button>
    </div>
  )
}

export default DetalleTest
