import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/shared/table/table'
import dayjs from 'dayjs'
import useOposicionesResponsedQuery from 'hooks/query/use-oposiciones-responsed.query'
import { Loader2Icon } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'

type Props = {}

const TabOposicionHistory = (props: Props) => {
  const { user } = useStore()
  const navigate = useNavigate()

  const { data, isLoading } = useOposicionesResponsedQuery({
    enabled: !!user?.id,
    variables: {
      sort: 'createdAt:desc',
      populate: ['oposicion,temario'],
      pagination: {
        pageSize: 1000
      },
      filters: {
        user: {
          id: { $eq: user?.id }
        },
        createdAt: {
          $gte: useMemo(
            () => dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
            []
          )
        }
      }
    }
  })

  if (isLoading) {
    return (
      <div className="w-full grid place-items-center pt-20">
        <Loader2Icon className="animate-spin" />
      </div>
    )
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Oposición</TableHead>
            <TableHead className="text-center">Tipo</TableHead>
            <TableHead className="text-center">Nota</TableHead>
            <TableHead className="text-center">Tiempo</TableHead>
            <TableHead className="text-center">Fecha</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.data.data.map((item) => {
            const isOposicion = !!item?.attributes?.oposicion?.data?.id
            const oposicion = item?.attributes?.oposicion?.data
            const temario = item?.attributes?.temario?.data

            return (
              <TableRow
                key={item.id}
                className=" cursor-pointer"
                onClick={() => navigate(`/actividad/${item.id}`)}
              >
                <TableCell>
                  {isOposicion
                    ? oposicion?.attributes?.nombre
                    : temario?.attributes?.title}
                </TableCell>
                <TableCell className="text-center">
                  {isOposicion ? 'Oposición' : 'Temario'}
                </TableCell>
                <TableCell className="text-center">
                  {item?.attributes?.average?.toFixed(1)}
                </TableCell>
                <TableCell className="text-center">
                  {dayjs(item?.attributes?.time, 'HH:mm:ss.SSS').format(
                    'mm:ss'
                  )}
                </TableCell>
                <TableCell className="text-center">
                  {dayjs(item?.attributes?.createdAt).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default TabOposicionHistory
