import { Card } from 'components/shared/card/card'
import { Book, MapPin } from 'lucide-react'
import { Link } from 'react-router-dom'

function PageHome() {
  return (
    <div className="w-[90vw] max-w-3xl min-h-[calc(100dvh_-_80px)] flex flex-col mx-auto py-8 md:py-10">
      <div className="mb-6">
        <h2 className="text-xl md:text-3xl font-semibold mb-3">
          ¿Como quieres preparar tu test?
        </h2>
      </div>
      <Link to="/convocatorias">
        <Card className="p-3 md:p-5 mb-3">
          <div className="flex items-center gap-3 mb-1">
            <MapPin className="w-6 h-6" />
            <h3 className="text-xl font-semibold mb-1">Convocatoria</h3>
          </div>
        </Card>
      </Link>
      <Link to="/temarios">
        <Card className="p-3 md:p-5 mb-3">
          <div className="flex items-center gap-3 mb-1">
            <Book className="w-6 h-6" />
            <h3 className="text-xl font-semibold mb-1">Temario</h3>
          </div>
        </Card>
      </Link>
    </div>
  )
}

export default PageHome
