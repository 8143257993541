import { PersonalTest } from 'utils/axios-sdk'

export const MIS_TEST: (PersonalTest & { id: number })[] = [
  {
    id: 1,
    title: 'Test 1',
    isPublic: false,
    customAsks: [
      {
        id: 1,
        description: '¿Cuánto es 2 + 2?',
        responseA: '1',
        responseB: '2',
        responseC: '3',
        responseD: '4',
        A: false,
        B: false,
        C: false,
        D: true,
        explicacionRespuesta: '2 + 2 = 4',
        createdAt: new Date(),
        updatedAt: new Date()
      },
      {
        id: 2,
        description: '¿Cuánto es 2 * 2?',
        responseA: '1',
        responseB: '2',
        responseC: '3',
        responseD: '4',
        A: false,
        B: false,
        C: false,
        D: true,
        explicacionRespuesta: '2 * 2 = 4',
        createdAt: new Date(),
        updatedAt: new Date()
      }
    ],
    temas: {
      data: [
        {
          id: 1,
          attributes: {
            title: 'Matemáticas',
            description: 'Test de matemáticas',
            createdAt: new Date(),
            updatedAt: new Date(),
            TemaRelation: {
              data: [
                {
                  id: 3,
                  attributes: {
                    description: '¿Cuánto es 3 + 3?',
                    responseA: '1',
                    responseB: '2',
                    responseD: '4',
                    responseC: '6',
                    A: false,
                    B: false,
                    C: false,
                    D: true,
                    explicacionRespuesta: '3 + 3 = 6',
                    createdAt: new Date(),
                    updatedAt: new Date()
                  }
                },
                {
                  id: 4,
                  attributes: {
                    description: '¿Cuánto es 3 * 3?',
                    responseA: '1',
                    responseB: '2',
                    responseD: '4',
                    responseC: '9',
                    A: false,
                    B: false,
                    C: false,
                    D: true,
                    explicacionRespuesta: '3 * 3 = 9',
                    createdAt: new Date(),
                    updatedAt: new Date()
                  }
                }
              ]
            }
          }
        }
      ]
    },
    createdAt: new Date(),
    updatedAt: new Date()
  }
]
