import { useMutation } from '@tanstack/react-query'
import { ChallengeType } from 'store/exam.store'

import { axiosClient } from 'utils/axios-client'
import { ResultadosReto, RetoMutationResponse } from 'utils/axios-sdk'

type UpdateRetoMutationVariables = Partial<{
  retoId: string
  creador: string
  participantes: string[]
  oposicion: string
  resultados: ResultadosReto[]
  asks: string[]
  slug: string
  type: ChallengeType
  typeCorrection: 'after' | 'instant'
}>

export const updateRetoMutation = ({
  retoId,
  ...props
}: UpdateRetoMutationVariables) => {
  return axiosClient.put<{ data: RetoMutationResponse }>('/retos/' + retoId, {
    data: props
  })
}

const useUpdateRetoMutation = () => {
  return useMutation({ mutationFn: updateRetoMutation })
}

export default useUpdateRetoMutation
